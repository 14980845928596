var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-overlay',{staticClass:"root",attrs:{"show":!_vm.isReady,"rounded":"lg","opacity":1}},[(!_vm.isPortrait && _vm.isReady)?_c('div',{staticClass:"menu"},[(_vm.isReady && _vm.menu.length === 0)?_c('div',{staticClass:"text"},[(!_vm.screen.disableImage)?_c('div',{staticClass:"flex-27 side-panel",style:([
          { 'border-right-color': _vm.screen.color },
          _vm.screen.backgroundImageLandscape
            ? {
                'background-image':
                  'url(' + _vm.screen.backgroundImageLandscape + ')',
              }
            : '',
        ])}):_vm._e(),_c('div',{staticClass:"flex-165 no-meal"},[_c('div',{domProps:{"textContent":_vm._s(_vm.screen.noMenuTitle)}})])]):(_vm.isReady && _vm.menu.length > 0)?_c('div',{staticClass:"meals"},[(!_vm.screen.disableImage)?_c('div',{staticClass:"flex-27 side-panel",style:([
          { 'border-right-color': _vm.screen.color },
          _vm.screen.backgroundImageLandscape
            ? {
                'background-image':
                  'url(' + _vm.screen.backgroundImageLandscape + ')',
              }
            : '',
        ])}):_vm._e(),_c('div',{staticClass:"flex-165",staticStyle:{"display":"flex","flex-flow":"column"}},[(_vm.screen.headerText && !_vm.screen.disableTitle)?_c('div',{staticClass:"header-text-wrapper",style:({ color: _vm.screen.color })},[_c('span',{staticClass:"header-text",domProps:{"textContent":_vm._s(_vm.screen.headerText)}}),_c('span',{staticClass:"date",domProps:{"textContent":_vm._s(_vm.currentDate)}}),_c('span',{staticClass:"time",domProps:{"textContent":_vm._s(_vm.currentTime)}})]):_vm._e(),_c('div',{staticClass:"daily-menu"},_vm._l((_vm.menu),function(f,i){return _c('div',{key:i,style:({
              'font-weight': i < _vm.screen.topBoldCount ? '600' : 'normal',
            })},[_c('div',{staticClass:"meal",style:({
                'text-decoration': f.soldOut ? 'line-through' : 'none',
              })},[_c('div',{staticClass:"number",style:({ 'background-color': _vm.screen.color })},[_c('div',{domProps:{"textContent":_vm._s(f.order)}})]),_c('div',{staticClass:"detail",style:({ 'border-bottom-color': _vm.mealDelimiterColor })},[_c('div',{domProps:{"textContent":_vm._s(f.name)}}),(f.currency === 'czk')?_c('div',{staticClass:"price",style:({ color: _vm.screen.color }),domProps:{"textContent":_vm._s(f.price + ',-')}}):(f.currency === 'eur')?_c('div',{staticClass:"price",style:({ color: _vm.screen.color }),domProps:{"textContent":_vm._s('€ ' + f.price)}}):(f.currency === 'usd')?_c('div',{staticClass:"price",style:({ color: _vm.screen.color }),domProps:{"textContent":_vm._s('$ ' + f.price)}}):_vm._e()])])])}),0)])]):_vm._e()]):_vm._e(),(_vm.isPortrait && _vm.isReady)?_c('div',{staticClass:"menu"},[(!_vm.screen.disableImage)?_c('div',{staticClass:"top-panel",style:(_vm.screen.backgroundImagePortrait
          ? {
              'background-image':
                'url(' + _vm.screen.backgroundImagePortrait + ')',
            }
          : '')}):_vm._e(),(_vm.screen.headerText && !_vm.screen.disableTitle)?_c('div',{staticClass:"header-text",style:({
        'background-color': _vm.screen.color,
      })},[_c('div',{domProps:{"textContent":_vm._s(_vm.screen.headerText)}}),_c('div',{staticClass:"data-time-wrapper"},[_c('span',{staticClass:"date",domProps:{"textContent":_vm._s(_vm.currentDate)}}),_c('span',{staticClass:"time",domProps:{"textContent":_vm._s(_vm.currentTime)}})])]):_vm._e(),_c('div',{staticClass:"daily-menu"},[(_vm.isReady && _vm.menu.length === 0)?_c('div',{staticClass:"no-meal"},[_c('div',{domProps:{"textContent":_vm._s(_vm.screen.noMenuTitle)}})]):(_vm.isReady && _vm.menu.length > 0)?_c('div',{staticClass:"meals"},_vm._l((_vm.menu),function(f,i){return _c('div',{key:i,style:({
            'font-weight': i < _vm.screen.topBoldCount ? '600' : 'normal',
          })},[_c('div',{staticClass:"meal"},[_c('div',{staticClass:"number",style:({ 'background-color': _vm.screen.color })},[_c('div',{domProps:{"textContent":_vm._s(f.order)}})]),_c('div',{staticClass:"detail",style:({ 'border-bottom-color': _vm.mealDelimiterColor })},[_c('div',{domProps:{"textContent":_vm._s(f.name)}}),(f.currency === 'czk')?_c('div',{staticClass:"price",style:({ color: _vm.screen.color }),domProps:{"textContent":_vm._s(f.price + ',-')}}):(f.currency === 'eur')?_c('div',{staticClass:"price",style:({ color: _vm.screen.color }),domProps:{"textContent":_vm._s('€ ' + f.price)}}):(f.currency === 'usd')?_c('div',{staticClass:"price",style:({ color: _vm.screen.color }),domProps:{"textContent":_vm._s('$ ' + f.price)}}):_vm._e()])])])}),0):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }