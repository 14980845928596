import firebase from "firebase/app";

import { MenuSource } from "./menuSource";
import { SelectedMeals, SelectedMeal } from "./types";

export class FirebaseMenuSource extends MenuSource {
  constructor(
    private db: firebase.database.Database,
    private dailyMenuPath: string,
    currency: string
  ) {
    super(currency);
  }

  start(): void {
    this.db
      .ref(this.dailyMenuPath)
      .on("value", this.onReadDailyMenu.bind(this), this.onError.bind(this));
  }

  stop(): void {
    this.db
      .ref(this.dailyMenuPath)
      .off("value", this.onReadDailyMenu.bind(this), this.onError.bind(this));
  }

  private async onReadDailyMenu(snapshot: firebase.database.DataSnapshot) {
    const meals: SelectedMeals = snapshot.val();
    if (!meals) {
      this.ev.dispatchEvent(new CustomEvent("new-data", { detail: [] }));
      return;
    }

    this.ev.dispatchEvent(
      new CustomEvent("new-data", {
        detail: Object.entries(meals)
          .map((value: [string, SelectedMeal]) => {
            const [, meal] = value;
            return {
              ...meal,
              currency: this.currency,
            };
          })
          .filter((f: SelectedMeal) => f.available)
          .sort((a: SelectedMeal, b: SelectedMeal) => a.order - b.order),
      })
    );
  }

  private onError(error: any) {
    console.error("Error: " + error.code);
  }
}
