<template>
  <b-overlay class="root" :show="isLoading" rounded="sm">
    <Settings
      :settings="screenSettings"
      @onScreenSettingsChanged="onScreenSettingsChanged"
    />
    <Organizations
      @onNewOrganization="onNewOrganization"
      @onChangeOrganization="onChangeOrganization"
      @inviteUserToOrganization="inviteUserToOrganization"
      @removeUserAccess="removeUserAccess"
      @renameSelectedOrganization="renameSelectedOrganization"
      :organization="organization"
      :isDataReady="organizationDataReady"
    />

    <div class="editor-wrap" v-if="!isLoading">
      <Header
        :username="email"
        :title="screenSettings.name"
        :organization="allOrganizationFlat"
        @onCopyScreenHref="onCopyScreenHref"
        @onLogout="onLogout"
        @onOrganizationSwitchClick="onOrganizationSwitchClick"
        @quickSwitchOrganization="onChangeOrganization"
        @onSettingsClick="onSettingsClick"
        @onLanguageChange="onLanguageChange"
      ></Header>
      <div class="editor" v-show="!isLoading">
        <FullMenu
          class="full-offer"
          @onSelectMeal="selectMeal"
          @onAddNewMeal="newMeal"
          @onEditMeal="editMeal"
          @onRemoveMeal="removeMeal"
          :meals="allMeal"
        ></FullMenu>
        <DailyMenu
          class="daily-offer"
          :meals="selectedMeals"
          @onEdit="editMealFromSelected"
          @onRemove="removeMealFromSelected"
        >
        </DailyMenu>
      </div>
    </div>
  </b-overlay>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import DailyMenu from "@/components/DailyMenu.vue";
import FullMenu from "@/components/FullMenu.vue";
import firebase from "firebase/app";
import {
  AllMeals,
  Meal,
  SelectedMeal,
  SelectedMeals,
  NewOrganizationPayload,
  Organization,
  Screen,
  OrganizationDbModel,
  User,
  ScreenSettings,
  ScreenDbModel,
  FlatOrganization,
  AddUserToOrganizationRequest,
  DeleteUserFromOrganizationRequest,
  RenameOrganization,
  MenuSourceType,
} from "@/types";
import {
  addUserToOrganization,
  deleteUserFromOrganization,
  findOrganization,
  getDataOnce,
  newOrganization,
  newScreen,
  renameOrganization,
} from "../firebaseHelpers";
import Settings from "../components/Settings.vue";
import Organizations from "../components/Organizations.vue";
import { defaultValues } from "@/defaultValues";
import { setLocale } from "@/i18n";
import { Lang } from "@/types";
import { emptyScreen } from "@/mapper";

@Component({
  components: {
    Header,
    DailyMenu,
    FullMenu,
    Settings,
    Organizations,
  },
})
export default class ScreenView extends Vue {
  public isLoading = true;
  public allMeal: AllMeals = {};
  public selectedMeals: SelectedMeals = {};
  private dailyMenuId = "";
  private fullMenuId = "";
  public email = "";
  private screenId = "";
  public organization: Organization[] = [];
  public allOrganizationFlat: FlatOrganization[] = [];
  public organizationDataReady = false;

  public screenSettings: ScreenSettings = {
    name: "",
    color: "",
    disableTitle: false,
    disableImage: false,
    screenScale: 1,
    noMenuTitle: "",
    currency: "",
    headerText: "",
    topBoldCount: 1,
    menuSourceUrl: "",
    menuSourceType: MenuSourceType.Default,
  };

  @Watch("$route.params", { immediate: true, deep: true })
  private routeParamsChanged() {
    this.isLoading = true;
    this.loadData().then(() => {
      this.isLoading = false;
      this.organizationDataReady = true;
    });
  }

  public async onLanguageChange(lang: string) {
    setLocale(lang);

    const user = firebase.auth().currentUser;
    if (!user) {
      return;
    }

    const db = firebase.database();
    await db.ref(`users/${user.uid}/`).update({ lang });
  }

  public async renameSelectedOrganization(payload: RenameOrganization) {
    this.organizationDataReady = false;
    try {
      const o = findOrganization(payload.organizationId, this.organization);
      if (!o) {
        throw new Error("unable to find organization");
      }

      o.name = payload.name;
      await renameOrganization(payload);
    } catch (err) {
      console.error(err);
      this.showError(
        this.$t("screen.organization.unableToRenameOrganization").toString()
      );
    }
    this.organizationDataReady = true;
  }

  public async removeUserAccess(payload: DeleteUserFromOrganizationRequest) {
    this.organizationDataReady = false;
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
      console.error("invalid token");
      return;
    }
    try {
      const o = findOrganization(payload.organizationId, this.organization);
      if (!o) {
        throw new Error("unable to find organization");
      }

      // Kontrola jestli uživatel již není v organizaci
      const idx = o.users?.findIndex((u) => {
        return u.email === payload.user.email;
      });
      if (idx === undefined || idx === -1) {
        this.showError(
          this.$t(
            `${this.$t(
              "screen.organization.error.userNotFoundInOrganization.user"
            )} ${payload.user.email} ${this.$t(
              "screen.organization.error.userNotFoundInOrganization.error"
            )}`
          ).toString()
        );
        return;
      }
      if (!o.users || o.users.length === 0) {
        return;
      }
      this.$delete(o.users, idx);

      await deleteUserFromOrganization(payload, token);
    } catch (err) {
      console.error(err);
      this.showError(
        this.$t("screen.error.unableToAddUserIntoOrganization").toString()
      );
    }
    this.organizationDataReady = true;
  }

  public async inviteUserToOrganization(payload: AddUserToOrganizationRequest) {
    this.organizationDataReady = false;
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
      console.error("invalid token");
      return;
    }
    try {
      const o = findOrganization(payload.organizationId, this.organization);
      if (!o) {
        throw new Error("unable to find organization");
      }

      // Kontrola jestli uživatel již není v organizaci
      const test = o.users?.find((u) => {
        return u.email === payload.user.email;
      });

      if (test) {
        this.showError(
          this.$t(
            `${this.$t(
              "screen.organization.error.userIsInOrganization.user"
            )} ${payload.user.email} ${this.$t(
              "screen.organization.error.userIsInOrganization.error"
            )}`
          ).toString()
        );
        return;
      }

      const u = await addUserToOrganization(payload, token);
      if (!u) {
        throw new Error("empty user reponse");
      }

      if (!o.users) {
        o.users = [];
      }
      o.users.push(u);
    } catch (err) {
      console.error(err);
      this.showError(
        this.$t("screen.error.unableToAddUserIntoOrganization").toString()
      );
    }
    this.organizationDataReady = true;
  }

  public onChangeOrganization(o: FlatOrganization) {
    const { screenId } = o;
    const newRoute = `/screen/${screenId}`;

    if (this.$route.path === newRoute) {
      return;
    }
    this.$router.push(newRoute);
  }

  private showError(msg: any) {
    this.$bvModal.msgBoxOk(msg, {
      title: this.$t("screen.error.title").toString(),
      size: "sm",
      buttonSize: "sm",
      okVariant: "danger",
      centered: true,
    });
  }

  public onCopyScreenHref() {
    const u = new URL(location.pathname, location.href);
    u.hash = `#/menu/screen/${this.screenId}/`;
    const el = document.createElement("textarea");
    el.value = u.href;
    el.setAttribute("readonly", "");
    el.style.display = "none";
    document.body.appendChild(el);
    el.select();
    const toasterSettings = {
      autoHideDelay: 1000,
      toaster: "b-toaster-top-center",
      solid: true,
      appendToast: true,
    };
    navigator.clipboard
      .writeText(u.href)
      .then(
        () => {
          this.$bvToast.toast(
            this.$t("screen.clipboard.titleOkMsg").toString(),
            {
              ...toasterSettings,
              title: this.$t("screen.clipboard.titleOk").toString(),
              variant: "success",
            }
          );
        },
        () => {
          this.$bvToast.toast(
            this.$t("screen.clipboard.titleErrorMsg").toString(),
            {
              ...toasterSettings,
              title: this.$t("screen.clipboard.titleError").toString(),
              variant: "danger",
            }
          );
        }
      )
      .finally(() => {
        document.body.removeChild(el);
      });
  }

  public onSettingsClick() {
    this.$root.$emit("bv::show::modal", "modalSettings");
  }

  public async onOrganizationSwitchClick() {
    const auth = firebase.auth();
    if (!auth.currentUser) {
      return;
    }

    this.$root.$emit("bv::show::modal", "modalGroups");
  }

  private getAllUniqueUsers(organization: Organization[]): User[] {
    const users: User[] = [];
    const alreadyAddedUsers: string[] = [];
    organization.forEach((o) => {
      let u: User[] = [];
      if (o.organization?.length !== 0) {
        u = this.getAllUniqueUsers(o.organization || []);
      }
      const mergedUsers: User[] = [...u, ...(o.users || [])];
      for (const u of mergedUsers) {
        if (!alreadyAddedUsers.includes(u.uid)) {
          alreadyAddedUsers.push(u.uid);
          users.push(u);
        }
      }
    });

    return users;
  }

  private getAllOrganizationFlat(
    organization: Organization[]
  ): FlatOrganization[] {
    const flatOrganization: FlatOrganization[] = [];
    organization.forEach((o) => {
      let fo: FlatOrganization[] = [];
      if (o.organization?.length !== 0) {
        fo = this.getAllOrganizationFlat(o.organization || []);
        flatOrganization.push(...fo);
      }

      flatOrganization.push({
        id: o.id,
        name: o.name,
        screenId: o.screens[0].id,
      });
    });

    return flatOrganization;
  }

  private async getAllSubOrganizations(
    orgIds: string[]
  ): Promise<Organization[]> {
    const db = firebase.database();

    return Promise.all(
      orgIds.map(async (id) => {
        const ref = db.ref(`organization/${id}/`);
        const organizationDb = await getDataOnce<OrganizationDbModel>(ref);
        let children: Organization[] = [];
        let ids: string[] = [];
        ids = Object.keys(organizationDb.organization || {});
        // Načtení všech podorganizací v dané organizaci pokud nějaké jsou
        if (ids.length !== 0) {
          children = await this.getAllSubOrganizations(ids);
        }

        // Načtení všech prodejen v dané organizaci pokud nějaké jsou
        ids = Object.keys(organizationDb.screens || {});
        let screens: Screen[] = [];
        if (ids.length !== 0) {
          screens = await this.getAllScreens(ids);
        }

        // Načtení všech uživatelů v dané organizaci pokud nějaké jsou
        ids = Object.keys(organizationDb.users || {});
        let users: User[] = [];
        if (ids.length !== 0) {
          users = await this.getAllUsers(ids);
          // Všichni uživatelé kromě přihlášeného
          users = users.filter(
            (u) => u.email !== firebase.auth().currentUser?.email
          );
        }

        return {
          id,
          name: organizationDb.name || "",
          organization: children,
          screens: screens,
          users,
        };
      })
    );
  }

  private normalizeBoolean(
    value: null | undefined | string | boolean
  ): boolean {
    if (value === null || value === undefined) {
      return false;
    } else if (typeof value === "string") {
      return value === "true";
    }

    return value;
  }

  private async getAllScreens(ids: string[]): Promise<Screen[]> {
    const db = firebase.database();
    return Promise.all(
      ids.map(async (id) => {
        const ref = db.ref(`screens/${id}/`);
        const c = await getDataOnce<ScreenDbModel>(ref);
        let defaultNoMenuTitle = defaultValues.en.noMenuTitle;
        if (!defaultValues[this.$i18n.locale]) {
          defaultNoMenuTitle = defaultValues[this.$i18n.locale].noMenuTitle;
        }

        const disableTitle = this.normalizeBoolean(c.disableTitle);
        const disableImage = this.normalizeBoolean(c.disableImage);

        let screenScale = c.screenScale;
        if (c.screenScale === undefined || c.screenScale === null) {
          screenScale = 1;
        }

        return {
          id,
          name: c.name,
          headerText: c.headerText,
          disableTitle,
          disableImage,
          screenScale,
          fullMenuId: c.fullMenuId,
          noMenuTitle: c.noMenuTitle || defaultNoMenuTitle,
          dailyMenuId: c.dailyMenuId,
          currency: c.currency,
          color: c.color,
          backgroundImagePortrait: c.backgroundImagePortrait?.path || "",
          backgroundImageLandscape: c.backgroundImageLandscape?.path || "",
          topBoldCount: c.topBoldCount,
          menuSourceUrl: c.menuSourceUrl,
          menuSourceType: c.menuSourceType,
        };
      })
    );
  }

  private async getAllUsers(ids: string[]): Promise<User[]> {
    const db = firebase.database();
    return Promise.all(
      ids.map(async (id) => {
        const ref = db.ref(`users/${id}/email/`);
        const email = await getDataOnce<string>(ref);
        return {
          uid: id,
          email,
        };
      })
    );
  }

  public async onScreenSettingsChanged(settings: ScreenSettings) {
    const db = firebase.database();
    await db.ref(`screens/${this.screenId}/`).update({
      name: settings.name,
      color: settings.color,
      noMenuTitle: settings.noMenuTitle,
      currency: settings.currency,
      disableTitle: settings.disableTitle,
      disableImage: settings.disableImage,
      screenScale: settings.screenScale,
      headerText: settings.headerText,
      topBoldCount: settings.topBoldCount,
      menuSourceType: settings.menuSourceType,
      menuSourceUrl: settings.menuSourceUrl,
    });

    this.screenSettings = settings;

    if (settings.backgroundImageLandscape) {
      const pathLandscape = `/${this.screenId}/custom-landscape.png`;
      const targetPath = `screens/${this.screenId}/backgroundImageLandscape`;
      this.uploadImage(
        pathLandscape,
        targetPath,
        settings.backgroundImageLandscape
      );
    }

    if (settings.backgroundImagePortrait) {
      const pathPortrait = `/${this.screenId}/custom-portrait.png`;
      const targetPath = `screens/${this.screenId}/backgroundImagePortrait`;
      this.uploadImage(
        pathPortrait,
        targetPath,
        settings.backgroundImagePortrait
      );
    }
  }

  private async uploadImage(path: string, targetPath: string, image: File) {
    const storageRef = firebase.storage().ref(path);

    const snapshot = storageRef.put(image, {
      cacheControl: "no-cache, max-age=864000",
    });

    const db = firebase.database();
    await db.ref(targetPath).update({
      path,
      generation: (await snapshot).metadata.generation,
    });
  }

  public async onNewOrganization(payload: NewOrganizationPayload) {
    // Vytvori novou podroganizaci a nastavi parenta na organizationId
    this.organizationDataReady = false;
    newScreen(payload.newOrganizationName, this.$i18n.locale as Lang)
      .then(async (screen) => {
        if (!screen) {
          this.showError(
            this.$t("screen.organization.unableToCreateOrganization").toString()
          );
          return;
        }

        const newChildOrganizationId = await newOrganization(
          payload.newOrganizationName,
          [screen]
        );
        if (!newChildOrganizationId) {
          this.showError(
            this.$t("screen.organization.unableToCreateOrganization").toString()
          );
          return;
        }

        const db = firebase.database();
        const refOrganization = db.ref(
          `organization/${payload.parentOrganizationId}/organization/`
        );

        const o = findOrganization(
          payload.parentOrganizationId,
          this.organization
        );
        if (o) {
          if (!o.organization) {
            o.organization = [];
          }
          o.organization.push({
            id: newChildOrganizationId,
            name: payload.newOrganizationName,
            screens: [screen],
            organization: [],
          });
        }

        await refOrganization.update({
          [newChildOrganizationId]: true,
        });
      })
      .catch(() => {
        this.showError(
          this.$t("screen.organization.unableToCreateOrganization").toString()
        );
      })
      .finally(() => {
        this.organizationDataReady = true;
      });
  }

  private async loadData() {
    const { currentUser } = firebase.auth();
    if (!currentUser) {
      return;
    }
    const db = firebase.database();
    const screenId = this.$route.params.id;

    let ref = db.ref(`screens/${screenId}/`);
    const screen: ScreenDbModel = await getDataOnce<ScreenDbModel>(ref);
    this.dailyMenuId = screen.dailyMenuId;
    this.fullMenuId = screen.fullMenuId;

    ref = db.ref(`fullMenu/${screen.fullMenuId}/meals`);
    const allMeal: AllMeals = (await getDataOnce<AllMeals>(ref)) || {};
    this.allMeal = allMeal;

    ref = db.ref(`dailyMenu/${screen.dailyMenuId}/meals`);
    const selectedMeals: SelectedMeals =
      (await getDataOnce<SelectedMeals>(ref)) || {};
    this.selectedMeals = selectedMeals;

    this.screenId = screenId;
    this.email = currentUser.email || "";

    document.title = `${screen.name} - Ki-Wi Menu - Ki-Wi Signage`;
    const disableTitle = this.normalizeBoolean(screen.disableTitle);
    const disableImage = this.normalizeBoolean(screen.disableImage);
    let screenScale = screen.screenScale;
    if (screenScale === null || screenScale === undefined) {
      screenScale = 1;
    }
    const defaultScreen = emptyScreen(this.$i18n.locale as Lang);
    this.screenSettings = {
      name: screen.name,
      color: screen.color,
      noMenuTitle: screen.noMenuTitle ?? defaultScreen.noMenuTitle,
      currency: screen.currency,
      headerText: screen.headerText,
      disableTitle,
      disableImage,
      screenScale,
      topBoldCount: screen.topBoldCount ?? defaultScreen.topBoldCount,
      menuSourceType: screen.menuSourceType ?? defaultScreen.menuSourceType,
      menuSourceUrl: screen.menuSourceUrl ?? defaultScreen.menuSourceUrl,
    };

    ref = db.ref(`users/${currentUser.uid}/organization`);
    const ccc =
      (await getDataOnce<{ [organizationId: string]: boolean }>(ref)) || {};

    const organizationIds = Object.keys(ccc);
    const organization = await this.getAllSubOrganizations(organizationIds);
    this.allOrganizationFlat =
      this.getAllOrganizationFlat(organization).reverse();

    this.organization = organization;
  }

  public editMealFromSelected(id: string, meal: SelectedMeal) {
    const db = firebase.database();
    db.ref(`dailyMenu/${this.dailyMenuId}/meals/${id}`).update(meal);
    this.$set(this.selectedMeals, id, meal);
  }

  public removeMealFromSelected(ids: string[]) {
    const db = firebase.database();

    ids.forEach((id: string) => {
      const ref = db.ref(`dailyMenu/${this.dailyMenuId}/meals/${id}`);
      ref.remove();
      this.$delete(this.selectedMeals, id);
    });
    Object.entries(this.selectedMeals)
      .sort((a: [string, SelectedMeal], b: [string, SelectedMeal]) => {
        return a[1].order - b[1].order;
      })
      .map((v: [string, SelectedMeal], index: number) => {
        if (index + 1 !== v[1].order) {
          v[1].order = index + 1;
          // V DB se aktualizují pouze ty položky, které mají změněné pořadí tj. pokud byla
          // odstraněna první položka musí do DB zapsat všechny, pokud byla odstraněna poslední,
          // tak upraví pouze poslední
          this.editMealFromSelected(v[0], v[1]);
        }
        return v;
      });
  }

  public selectMeal(mealId: string) {
    const isExists = Boolean(this.selectedMeals[mealId]);
    if (isExists) {
      this.$bvModal.msgBoxOk(this.$t("screen.error.alreadyAdded").toString(), {
        title: this.$t("screen.error.title").toString(),
        okVariant: "success",
      });
      return;
    }

    const meal = this.allMeal[mealId];
    const f: SelectedMeal = {
      ...meal,
      available: true,
      order: Object.keys(this.selectedMeals).length + 1,
    };

    const db = firebase.database();
    db.ref(`dailyMenu/${this.dailyMenuId}/meals/${mealId}`).set(f);
    this.$set(this.selectedMeals, mealId, f);
  }

  public newMeal(meal: Meal) {
    const db = firebase.database();
    const meals = db.ref(`fullMenu/${this.fullMenuId}/meals`);
    const ref = meals.push();
    if (!ref.key) {
      // TODO: mozna nejaky error
      return;
    }
    ref.set(meal);
    this.$set(this.allMeal, ref.key, meal);
  }

  public editMeal(id: string, meal: Meal) {
    const db = firebase.database();
    db.ref(`fullMenu/${this.fullMenuId}/meals/${id}`).update(meal);
    this.$set(this.allMeal, id, meal);
  }

  public removeMeal(mealId: string) {
    const db = firebase.database();
    db.ref(`fullMenu/${this.fullMenuId}/meals/${mealId}`).remove();

    this.$delete(this.allMeal, mealId);
  }

  public async onLogout() {
    const auth = firebase.auth();
    await auth.signOut();
    this.$router.replace("/signin");
  }
}
</script>

<style lang="less" scoped>
@import "../layout.less";

.root {
  .full-screen();
}

.login-wrap {
  .flex-column();
  .vertical-center();
  .horizontal-center();
}

.editor-wrap {
  .flex-column();
}

.full-offer {
  margin: 1%;
  flex: 1;
}

.daily-offer {
  margin: 1%;
  flex: 1;
}

.login {
  width: 30%;
}

.editor {
  .flex-row();
  .horizontal-space-around();
  .vertical-start();
}

@media only screen and (max-width: 1365px) {
  .editor {
    .flex-row();
    .horizontal-space-around();
    .vertical-start();
    flex-wrap: wrap-reverse;
  }

  .full-offer {
    width: 100%;
    flex: initial;
  }

  .daily-offer {
    width: 100%;
    flex: initial;
  }
}
</style>
