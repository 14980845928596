<template>
  <b-overlay class="root" :show="!isReady" rounded="lg" :opacity="1">
    <Menu
      v-if="!sourceUnavailableError"
      :isReady="isReady"
      :menu="menu"
      :screen="screen"
    ></Menu>
    <div
      class="error-message"
      v-else
      v-show="isReady && sourceUnavailableError"
    >
      Data source is unavailable!
    </div>
  </b-overlay>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";

import { SelectedMeal, ScreenDbModel, MenuSourceType, Lang } from "@/types";
import { MenuSource } from "@/menuSource";
import { AdapteeMenuSource } from "@/menuSourceAdaptee";
import { FirebaseMenuSource } from "@/menuSourceFirebase";
import { emptyScreen, screenDbModelToScreen } from "@/mapper";

import Menu from "../components/Menu.vue";

@Component({
  components: {
    Menu,
  },
})
export default class ScreenMenu extends Vue {
  public isReady = false;
  private menuSource: MenuSource | null = null;
  public menu: SelectedMeal[] = [];

  private db: firebase.database.Database = firebase.database();
  private dataRefreshInterval = 30;

  public screen = emptyScreen(this.$i18n.locale as Lang);
  public sourceUnavailableError = false;
  public firstBold = true;

  private dailyMenuPath = "";

  constructor() {
    super();

    const { refreshInterval } = this.$route.query;

    const dataRefreshInterval = parseInt(
      this.queryToString(refreshInterval, ""),
      10
    );
    if (!isNaN(dataRefreshInterval) && this.dataRefreshInterval > 0) {
      this.dataRefreshInterval = dataRefreshInterval;
    }
  }

  get screenPath() {
    return `screens/${this.$route.params.id}/`;
  }

  mounted() {
    this.db
      .ref(this.screenPath)
      .on("value", this.onReadScreen.bind(this), this.onError.bind(this));
    if (!navigator.onLine) {
      this.useOfflineData();
    }
  }

  useOfflineData() {
    try {
      const screenDataStr = localStorage.getItem(
        `kiwi-menu/${this.screenPath}`
      );
      if (!screenDataStr) {
        throw new Error("no offline data");
      }
      const screenData = JSON.parse(screenDataStr);
      const { dailyMenu, screen } = screenData;
      this.screen = screen;
      this.menu = dailyMenu;
      this.sourceUnavailableError = false;
      this.isReady = true;
    } catch {
      this.sourceUnavailableError = true;
      return;
    }
  }

  destroyed() {
    this.db
      .ref(this.screenPath)
      .off("value", this.onReadScreen.bind(this), this.onError.bind(this));
    this.menuSource?.stop();
    this.menuSource?.removeEventListener(
      "new-data",
      this.menuUpdate.bind(this)
    );
    this.menuSource?.removeEventListener("error", this.onError.bind(this));
  }

  private queryToString(
    value: string | (string | null)[],
    defaultValue = ""
  ): string {
    if (Array.isArray(value)) {
      return value[0] ?? defaultValue;
    }
    return value ?? defaultValue;
  }

  private async onReadScreen(snapshot: firebase.database.DataSnapshot) {
    const screenDb: ScreenDbModel = snapshot.val();
    if (!screenDb) {
      console.error("screen not exists");
      return;
    }

    const screen = await screenDbModelToScreen(
      screenDb,
      this.$i18n.locale as Lang
    );
    this.dailyMenuPath = `dailyMenu/${screenDb.dailyMenuId}/meals`;

    const html = document.querySelector("html") as HTMLElement;
    if (html) {
      html.style.fontSize = `${16 * screen.screenScale}px`;
    }

    // TODO: Pro více zdrojů by se možná hodila nějaká factory.
    if (screen.menuSourceType === MenuSourceType.Adaptee) {
      this.menuSource = new AdapteeMenuSource(
        screenDb.menuSourceUrl,
        this.dataRefreshInterval,
        screen.currency
      );
    } else if (screen.menuSourceType === MenuSourceType.Default) {
      const db = firebase.database();
      this.menuSource = new FirebaseMenuSource(
        db,
        this.dailyMenuPath,
        screen.currency
      );
    }
    if (this.menuSource === null) {
      this.sourceUnavailableError = true;
      return;
    }
    this.screen = screen;
    this.sourceUnavailableError = false;
    this.menuSource.addEventListener("new-data", this.menuUpdate.bind(this));
    this.menuSource.addEventListener("error", this.onError.bind(this));
    this.menuSource.start();
  }

  private menuUpdate(e: CustomEvent<SelectedMeal[]>) {
    localStorage.setItem(
      `kiwi-menu/${this.screenPath}`,
      JSON.stringify({
        screen: this.screen,
        dailyMenu: e.detail,
      })
    );
    this.sourceUnavailableError = false;
    this.isReady = true;
    this.menu = e.detail;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onError(error: any) {
    console.error("Error: " + error);
    this.useOfflineData();
  }
}
</script>

<style lang="less" scoped>
@import "../layout.less";

.root {
  .full-screen();
  user-select: none;
}

.error-message {
  .full-screen();
  font-size: 2rem;
  .flex-row();
  .vertical-center();
  .horizontal-center();
}
</style>
