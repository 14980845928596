<template>
  <b-overlay class="root" :show="isLoading" rounded="sm">
    <div class="signin-wrapper">
      <div class="full-screen">
        <b-modal
          id="modal-forgotten-password"
          :title="$t('signin.forgottenPasswordModal.title')"
          @show="forgottenPasswordEmail = ''"
          @ok="
            () => {
              requestPasswordReset(forgottenPasswordEmail);
            }
          "
        >
          <form ref="form">
            <b-form-group
              :state="validationForgottenPasswordEmail"
              :label="$t('signin.forgottenPasswordModal.emailInputLabel')"
              label-for="forgotten-password-input"
              :invalid-feedback="
                $t('signin.forgottenPasswordModal.emailInputInvalidFeedback')
              "
            >
              <b-form-input
                id="forgotten-password-input"
                v-model="forgottenPasswordEmail"
                type="email"
                :state="validationForgottenPasswordEmail"
                :placeholder="$t('signin.forgottenPasswordModal.enterEmail')"
                required
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>

        <img src="../assets/logo.png" alt="" width="150" />
        <div class="kiwi-menu-logo">Ki-Wi Menu</div>
        <form>
          <div class="signin-title" v-text="$t('signin.title')" />
          <b-form-input
            class="mb-3 rounded-input"
            type="email"
            v-model="email"
            :placeholder="$t('signin.enterEmail')"
            :state="validationEmail"
          ></b-form-input>
          <b-form-input
            class="mb-3 rounded-input"
            type="password"
            id="text-password"
            v-model="password"
            :placeholder="$t('signin.enterPassword')"
            :state="validationPassword"
          ></b-form-input>

          <b-form-select
            @change="languageChange"
            v-model="currentLang"
            class="mb-3 rounded-input"
          >
            <b-form-select-option
              value="cs"
              v-text="$t('signin.selectLang.czech')"
            />
            <b-form-select-option
              value="en"
              v-text="$t('signin.selectLang.english')"
            />
          </b-form-select>

          <button
            class="btn btn-lg mt-3 btn-block signin-btn"
            @click="
              (e) => {
                login(e, email, password);
              }
            "
            v-text="$t('signin.signinButton')"
          />
          <button
            class="btn btn-sm mt-3 btn-block forgotten-password-btn"
            @click="forgotPassword"
            v-text="$t('signin.forgottenPasswordButton')"
          />
        </form>
      </div>
    </div>
  </b-overlay>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";
import { checkEmail, getDataOnce, getSignInUrl } from "@/firebaseHelpers";
import { Screen } from "@/types";
import { setLocale } from "@/i18n";

@Component({})
export default class SignIn extends Vue {
  public isLoading = false;

  public email = "";
  public password = "";
  public forgottenPasswordEmail = "";
  public currentLang = "";

  constructor() {
    super();
    this.currentLang = this.$i18n.locale;
  }

  async mounted(): Promise<void> {
    const user = firebase.auth().currentUser;
    if (user && user.emailVerified) {
      this.redirectDefaultScreen(user.uid);
    } else if (!user?.emailVerified) {
      await firebase.auth().signOut();
    }
  }

  private async redirectDefaultScreen(uid: string) {
    const db = firebase.database();

    // Získání id výchozí obrazovky na kterou se přejde
    const defaultScreenIdSnapShot = await db
      .ref(`users/${uid}/defaultScreenId`)
      .get();
    const defaultScreenId: string = defaultScreenIdSnapShot.val();
    // Ověření, že prodejna se získaným id existuje
    const ref = db.ref(`screens/${defaultScreenId}/`);
    const screen = await getDataOnce<Screen | null>(ref);
    if (!screen) {
      throw new Error("system error, unknown screenId");
    }

    const langSnapShot = await db.ref(`users/${uid}/lang`).get();
    let lang: string;
    if (!langSnapShot || !langSnapShot.val()) {
      lang = "en";
    } else {
      lang = langSnapShot.val();
    }
    setLocale(lang);
    this.$router.replace(`screen/${defaultScreenId}`);
  }

  public async login(e: Event, email: string, password: string) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.validationEmail || !this.validationPassword) {
      return;
    }

    const auth = firebase.auth();
    this.isLoading = true;
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    auth
      .signInWithEmailAndPassword(email, password)
      .then(async () => {
        const auth = firebase.auth();
        if (!auth || !auth.currentUser) {
          throw new Error("error while logging");
        }
        if (!auth.currentUser.emailVerified) {
          auth.languageCode = this.$i18n.locale;
          await auth.currentUser.sendEmailVerification({
            url: getSignInUrl(this.$router),
          });
          throw new Error("email not verified");
        }
        await this.redirectDefaultScreen(auth.currentUser.uid);
        this.isLoading = true;
      })
      .catch((err) => {
        console.error(err);
        firebase.auth().signOut();
        if (err.toString() && err.toString().includes("email not verified")) {
          this.$bvModal.msgBoxOk(
            this.$t("signin.emailNotVerifiedMsgBox.text").toString(),
            {
              title: this.$t("signin.emailNotVerifiedMsgBox.title").toString(),
              size: "sm",
              buttonSize: "sm",
              okVariant: "warning",
              centered: true,
            }
          );
          return;
        } else if (err.toString().startsWith("system error")) {
          this.$bvModal.msgBoxOk(
            this.$t("signin.systemErrorMsgBox.text").toString(),
            {
              title: this.$t("signin.systemErrorMsgBox.text").toString(),
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              centered: true,
            }
          );
          return;
        }
        this.$bvModal.msgBoxOk(
          this.$t("signin.wrongPasswordMsgBox.text").toString(),
          {
            title: this.$t("signin.wrongPasswordMsgBox.title").toString(),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            centered: true,
          }
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public languageChange() {
    setLocale(this.currentLang);
  }

  get validationForgottenPasswordEmail(): boolean {
    return Boolean(checkEmail(this.forgottenPasswordEmail));
  }

  get validationEmail(): boolean {
    return Boolean(checkEmail(this.email));
  }

  get validationPassword(): boolean {
    return this.password.length > 5;
  }

  public forgotPassword(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.$root.$emit("bv::show::modal", "modal-forgotten-password");
  }

  public requestPasswordReset(email: string) {
    if (!this.validationForgottenPasswordEmail) {
      return;
    }
    firebase.auth().languageCode = this.$i18n.locale;
    firebase
      .auth()
      .sendPasswordResetEmail(email, {
        url: getSignInUrl(this.$router),
      })
      .then(() => {
        this.$bvModal.msgBoxOk(
          this.$t("signin.forgottenPasswordSuccessMsgBox.text").toString(),
          {
            title: this.$t(
              "signin.forgottenPasswordSuccessMsgBox.title"
            ).toString(),
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            centered: true,
          }
        );
      });
  }
}
</script>

<style lang="less" scoped>
@import "../layout.less";
.root {
  .full-screen();
  background-image: url("../assets/signin-bg.png");
  background-color: rgba(73, 73, 73, 0.5);
  background-size: cover;
  background-position: center center;

  display: flex;
  justify-content: center;
  padding-top: 6rem;
}

.kiwi-menu-logo {
  background-color: #39b54a;
  border-radius: 0.5rem;
  font-size: 2.5rem;
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 0 0.5rem 0;
  margin: 2.5rem 0 6.3rem 0;
}

.signin-wrapper {
  width: 25rem;
}

.signin-title {
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
}

.rounded-input {
  border-radius: 2.5rem;
  height: 3rem;
}

.signin-btn {
  background-color: #005938;
  color: #fff;
  border-radius: 2.5rem;
  width: 80%;
  margin: 0 auto;
}

.forgotten-password-btn {
  text-decoration: underline;
  color: #fff;
}
</style>
