import Vue from "vue";
import VueI18n from "vue-i18n";
import cs from "./locales/cs.json";
import en from "./locales/en.json";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "en",
  fallbackLocale: "en",
  messages: {
    cs,
    en,
  },
});

export const setLocale = (locale: string) => {
  localStorage.setItem("lang", locale);
  i18n.locale = locale;
};
