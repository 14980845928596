<template>
  <div class="header">
    <div class="left">
      <img src="../assets/small-logo.png" />
      <div v-text="title"></div>
    </div>
    <div class="right">
      <div v-text="username" class="username"></div>
      <b-dropdown
        id="dropdown-left"
        :text="$t('screen.header.organizationChangeButton')"
        variant="dark"
        class="m-2 organization-quick-switch"
      >
        <div v-if="organization.length !== 0">
          <div
            class="organization-name"
            v-for="(o, idx) in organization"
            :key="idx"
            @click="quickSwitchOrganization(o)"
          >
            <b-dropdown-item-button
              class="organization-quick-switch-item"
              v-text="o.name"
            ></b-dropdown-item-button>
          </div>
        </div>
        <b-dropdown-text
          v-else
          v-text="$t('screen.header.noOrganizationQuickChange')"
        />
      </b-dropdown>

      <b-button
        size="sm"
        @click="groupSwitch()"
        class="mr-1 btn-dark font-size-1-rem"
        :title="$t('screen.header.organizationManagementBtnHint')"
      >
        <b-icon icon="building"></b-icon>
      </b-button>

      <b-button
        size="sm"
        @click="settings()"
        class="mr-1 btn-dark font-size-1-rem"
        :title="$t('screen.header.screenSettingsBtnHint')"
      >
        <b-icon icon="gear"></b-icon>
      </b-button>

      <b-button
        size="sm"
        @click="copyScreenLink()"
        class="btn-dark font-size-1-rem"
        :title="$t('screen.header.copyScreenUrlBtnHint')"
      >
        <b-icon icon="clipboard"></b-icon>
      </b-button>

      <b-dropdown
        ref="lang-dropdown"
        id="lang-dropdown"
        :text="currentLang.toUpperCase()"
        variant="dark"
        class="m-2"
      >
        <div>
          <div class="organization-name" @click="languageChange('en')">
            <b-dropdown-item-button
              class="organization-quick-switch-item"
              v-text="'EN'"
            ></b-dropdown-item-button>
          </div>
          <div class="organization-name" @click="languageChange('cs')">
            <b-dropdown-item-button
              class="organization-quick-switch-item"
              v-text="'CZ'"
            ></b-dropdown-item-button>
          </div>
        </div>
      </b-dropdown>

      <b-button
        size="sm"
        @click="$emit('onLogout')"
        class="mr-1 btn-warning logout"
        v-text="$t('screen.header.signoutBtn')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { FlatOrganization } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Header extends Vue {
  @Prop() username!: string;
  @Prop() title!: string;
  @Prop() organization!: FlatOrganization[];
  public currentLang = "";

  constructor() {
    super();
    this.currentLang = this.$i18n.locale;
  }

  public languageChange(lang: string) {
    this.currentLang = lang;
    (this.$refs["lang-dropdown"] as any).hide(true);
    this.$emit("onLanguageChange", lang);
  }

  public quickSwitchOrganization(o: FlatOrganization) {
    this.$emit("quickSwitchOrganization", o);
  }

  public settings() {
    this.$emit("onSettingsClick");
  }

  public groupSwitch() {
    this.$emit("onOrganizationSwitchClick");
  }

  public copyScreenLink() {
    this.$emit("onCopyScreenHref");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "../layout.less";

.header {
  .flex-row();
  .vertical-center();
  .horizontal-space-between();
  background-color: gray;
  padding: 0.4rem;

  color: white;

  .left {
    flex: 1;
    .flex-row();
    .vertical-center();
    font-size: 150%;

    img {
      width: 13%;
      margin-right: 2%;
    }
  }

  .right {
    flex: 2;
    .flex-row();
    .vertical-center();
    .horizontal-end();

    .username {
      margin-right: 1%;
    }
  }

  .organization-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .organization-name {
    background-color: #fff;
    cursor: pointer;
  }

  .organization-name:hover {
    opacity: 0.75;
  }

  .organization-name:nth-child(even) {
    background-color: #f8f8f8;
  }
}

.organization-quick-switch {
  width: 12rem;
}

.organization-quick-switch-item {
  padding-left: 0.7rem;
  padding: 0.5rem;
}

.lang-change {
  width: 4rem;
}

.font-size-1-rem {
  font-size: 1.1rem;
}

.logout {
  width: 6rem;
}

@media only screen and (max-width: 1365px) {
  .header {
    .left {
      flex: 1;
      img {
        width: 8%;
      }
    }
  }
  .username {
    display: none;
  }
}
</style>
