import firebase from "firebase/app";

import { defaultValues } from "./defaultValues";
import { Lang, MenuSourceType, Screen, ScreenDbModel } from "./types";

import defaultImageLandscape from "./assets/side-panel.png";
import defaultImagePortrait from "./assets/top-panel.png";

function toBoolean(value: string | boolean): boolean {
  if (typeof value == "boolean") {
    return value;
  }
  return value === "true";
}

export function emptyScreen(lang: Lang): Screen {
  return {
    id: "",
    name: "",
    headerText: "",
    disableTitle: false,
    disableImage: false,
    screenScale: 1,
    noMenuTitle: defaultValues[lang].noMenuTitle,
    currency: defaultValues[lang].currency,
    fullMenuId: "",
    dailyMenuId: "",
    color: defaultValues.colorTheme,
    backgroundImageLandscape: "",
    backgroundImagePortrait: "",
    topBoldCount: 1,
    menuSourceType: MenuSourceType.Default,
    menuSourceUrl: "",
  };
}

export async function screenDbModelToScreen(
  screen: ScreenDbModel,
  lang: Lang
): Promise<Screen> {
  let backgroundImagePortrait = defaultImagePortrait;
  let backgroundImageLandscape = defaultImageLandscape;

  if (screen.backgroundImagePortrait && screen.backgroundImagePortrait.path) {
    const storageRef = firebase
      .storage()
      .ref(screen.backgroundImagePortrait.path);
    const url = await storageRef.getDownloadURL();

    backgroundImagePortrait = url;
  }

  if (screen.backgroundImageLandscape && screen.backgroundImageLandscape.path) {
    const storageRef = firebase
      .storage()
      .ref(screen.backgroundImageLandscape.path);
    const url = await storageRef.getDownloadURL();

    backgroundImageLandscape = url;
  }
  const defaultEmptyScreen = emptyScreen(lang);
  return {
    id: screen.id,
    name: screen.name,
    headerText: screen.headerText,
    disableTitle: toBoolean(screen.disableTitle),
    disableImage: toBoolean(screen.disableImage),
    screenScale: screen.screenScale,
    noMenuTitle: screen.noMenuTitle,
    currency: screen.currency,
    fullMenuId: screen.fullMenuId,
    dailyMenuId: screen.dailyMenuId,
    color: screen.color,
    backgroundImageLandscape: backgroundImageLandscape,
    backgroundImagePortrait: backgroundImagePortrait,
    menuSourceType: screen.menuSourceType ?? defaultEmptyScreen.menuSourceType,
    menuSourceUrl: screen.menuSourceUrl ?? defaultEmptyScreen.menuSourceUrl,
    topBoldCount: screen.topBoldCount ?? defaultEmptyScreen.topBoldCount,
  };
}
