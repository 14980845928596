<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { serviceWorker } from "./registerServiceWorker";

@Component({
  components: {},
})
export default class App extends Vue {
  mounted(): void {
    serviceWorker.addEventListener("new-version", () => {
      window.location.reload();
    });
  }
}
</script>

<style lang="less">
@import "layout.less";

#app {
  .full-screen();
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-sizing: border-box;
}
</style>
