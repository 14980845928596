import "./main.less";

import "mutationobserver-shim";
import "event-target-polyfill";

import "./plugins/bootstrap-vue";
import Vue from "vue";
import App from "./App.vue";

import router from "./router";

import Verte from "verte";
import "verte/dist/verte.css";

import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { i18n } from "./i18n";
import firebaseConfig from "./firebaseConfig.json";
import packageJson from "../package.json";

console.info(`${packageJson.name}:${packageJson.version}`);

// register component globally
Vue.component("verte", Verte);

Vue.config.productionTip = false;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Čekání na obnovení session
const unsubscribe = firebase.auth().onAuthStateChanged(() => {
  new Vue({
    router,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");

  unsubscribe();
});
