<template>
  <div>
    <form @submit="register" autocomplete="off">
      <h1 class="signup-title" v-text="$t('signup.title')" />
      <b-form-select
        @change="languageChange"
        v-model="currentLang"
        class="mb-3 rounded-input"
      >
        <b-form-select-option
          value="cs"
          v-text="$t('signin.selectLang.czech')"
        />
        <b-form-select-option
          value="en"
          v-text="$t('signin.selectLang.english')"
        />
      </b-form-select>
      <b-form-input
        class="mb-3 rounded-input"
        type="text"
        v-model="organizationName"
        :placeholder="$t('signup.enterOrganization')"
        :state="validationOrganization"
        @change="changeOrganization"
        autocomplete="off"
      ></b-form-input>

      <b-form-input
        class="mb-3 rounded-input"
        type="email"
        v-model="email"
        :placeholder="$t('signup.enterEmail')"
        :state="validationEmail"
        @change="changeEmail"
        autocomplete="off"
      ></b-form-input>
      <b-form-input
        class="mb-3 rounded-input"
        type="password"
        id="text-password"
        v-model="password"
        :placeholder="$t('signup.enterPassword')"
        :state="validationPassword"
        @change="changePassword"
        autocomplete="off"
      ></b-form-input>
      <b-form-input
        class="mb-3 rounded-input"
        type="password"
        id="text-password2"
        v-model="password2"
        :placeholder="$t('signup.repeatPassword')"
        :state="validationPassword2"
        @change="changePassword"
        autocomplete="off"
      ></b-form-input>
      <b-button
        class="registration-btn"
        type="submit"
        variant="success"
        :disabled="
          !validationEmail || !validationPassword2 || !validationOrganization
        "
        v-text="$t('signup.registrationButton')"
      />
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { NewUser } from "@/types";
import { checkEmail } from "@/firebaseHelpers";
import { setLocale } from "@/i18n";

@Component({})
export default class SignUp extends Vue {
  public email = "";
  public password = "";
  public password2 = "";
  public organizationName = "";
  private organizationNameChanged = false;
  private emailChanged = false;
  private passwordChanged = false;
  public currentLang = "";

  constructor() {
    super();
    this.currentLang = this.$i18n.locale;
  }

  public languageChange() {
    setLocale(this.currentLang);
  }

  get validationOrganization(): boolean | null {
    if (!this.organizationNameChanged) {
      return null;
    }
    return this.organizationName.length > 2;
  }

  get validationEmail(): boolean | null {
    if (!this.emailChanged) {
      return null;
    }
    return checkEmail(this.email);
  }

  get validationPassword(): boolean | null {
    if (!this.passwordChanged) {
      return null;
    }
    return this.password.length > 7;
  }

  get validationPassword2(): boolean | null {
    if (!this.passwordChanged) {
      return null;
    }
    return this.password2.length > 7 && this.password === this.password2;
  }

  public changeOrganization() {
    this.organizationNameChanged = true;
  }

  public changePassword() {
    this.passwordChanged = true;
  }

  public changeEmail() {
    this.emailChanged = true;
  }

  public register(e: Event) {
    this.organizationNameChanged = true;
    this.passwordChanged = true;
    this.emailChanged = true;
    e.stopPropagation();
    e.preventDefault();
    if (
      !this.validationEmail ||
      !this.validationPassword2 ||
      !this.validationOrganization
    ) {
      return;
    }
    const user: NewUser = {
      email: this.email,
      password: this.password,
      organizationName: this.organizationName,
      lang: this.currentLang,
    };

    this.$emit("onRegister", user);
    this.email = "";
    this.password = "";
    this.password2 = "";
    this.organizationName = "";
  }
}
</script>

<style scoped lang="less">
@import "../layout.less";

.signup-title {
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
}

.rounded-input {
  border-radius: 2.5rem;
  height: 2.9rem;
}
.registration-btn {
  background-color: rgb(0, 89, 56);
  color: rgb(255, 255, 255);
  border-radius: 2.5rem;
  width: 80%;
  margin: 0px auto;
}
</style>
