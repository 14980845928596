<template>
  <b-modal
    id="modalGroups"
    ref="modalGroups"
    :title="modalTitle"
    size="xl"
    :cancel-disabled="true"
    :ok-disabled="true"
  >
    <b-modal
      id="modalNewOrganization"
      ref="modalNewOrganization"
      :title="$t('screen.organization.modalNewOrganization.title')"
      @ok="onSubmitNewOrganization"
    >
      <template #default="{ ok }">
        <form ref="form" @submit.stop.prevent="ok()">
          <b-form-group
            :label="$t('screen.organization.modalNewOrganization.nameLabel')"
          >
            <b-form-input
              v-model="newOrganizationName"
              :invalidFeedback="
                $t(
                  'screen.organization.modalNewOrganization.nameInvalidFeedback'
                )
              "
              :state="newOrganizationName.length > 3"
              autofocus
            ></b-form-input>
          </b-form-group>
        </form>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="modal-footer-buttons">
          <div>
            <b-button
              class="mr-2"
              size="sm"
              variant="success"
              @click="ok()"
              v-text="$t('screen.organization.modalNewOrganization.okButton')"
            />
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="cancel()"
              v-text="
                $t('screen.organization.modalNewOrganization.cancelButton')
              "
            />
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modalEditOrganization"
      ref="modalEditOrganization"
      :title="$t('screen.organization.modalEditOrganization.title')"
      @ok="onSubmitEditOrganization"
    >
      <template #default="{ ok }">
        <form ref="form" @submit.stop.prevent="ok()">
          <b-form-group
            :label="$t('screen.organization.modalEditOrganization.nameLabel')"
          >
            <b-form-input
              v-model="editOrganizationName"
              :invalidFeedback="
                $t(
                  'screen.organization.modalEditOrganization.nameInvalidFeedback'
                )
              "
              :state="editOrganizationName.length !== 0"
              autofocus
            ></b-form-input>
          </b-form-group>
        </form>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="modal-footer-buttons">
          <div>
            <b-button
              class="mr-2"
              size="sm"
              variant="success"
              @click="ok()"
              v-text="$t('screen.organization.modalEditOrganization.okButton')"
            />
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="cancel()"
              v-text="
                $t('screen.organization.modalEditOrganization.cancelButton')
              "
            />
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modalInviteUser"
      ref="modalInviteUser"
      :title="$t('screen.organization.modalInviteUser.title')"
      @ok="inviteUserToOrganization"
    >
      <template #default="{ ok }">
        <form ref="form" @submit.stop.prevent="ok()">
          <b-form-group
            :label="$t('screen.organization.modalInviteUser.emailLabel')"
          >
            <b-form-input
              v-model="invitationEmail"
              :invalidFeedback="
                $t('screen.organization.modalInviteUser.emailInvalidFeedback')
              "
              :placeholder="
                $t('screen.organization.modalInviteUser.emailPlaceholder')
              "
              :state="validationEmail"
              autofocus
            ></b-form-input>
          </b-form-group>
        </form>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="modal-footer-buttons">
          <div>
            <b-button
              class="mr-2"
              size="sm"
              variant="success"
              @click="ok()"
              v-text="$t('screen.organization.modalInviteUser.inviteButton')"
            />
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="cancel()"
              v-text="$t('screen.organization.modalInviteUser.cancelButton')"
            />
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modalOrganizationByEmail"
      ref="modalOrganizationByEmail"
      :title="$t('screen.organization.modalOrganizationByEmail.title')"
    >
      <template #default="{}">
        <div
          v-if="filteredOrganization.length !== 0"
          class="modal-organization-by-email-wrapper"
        >
          <div
            v-for="(o, idx) in filteredOrganization"
            :key="idx"
            class="organization"
          >
            <div class="name" v-text="o.name"></div>
            <button
              class="btn btn-sm mt-3 btn-success btn-block navigate"
              @click="
                navigateToScreen({
                  id: o.id,
                  name: o.name,
                  screenId: o.screenId,
                })
              "
              v-text="
                $t('screen.organization.modalOrganizationByEmail.switchButton')
              "
            />
          </div>
        </div>
        <div v-else class="flex-row vertical-center">
          <div
            v-text="
              $t(
                'screen.organization.modalOrganizationByEmail.noOrganizationFound'
              )
            "
          />
        </div>
      </template>

      <template #modal-footer="{}">
        <div />
      </template>
    </b-modal>

    <b-overlay class="root" :show="!isDataReady" rounded="sm" opacity="1">
      <div class="organization-wrapper">
        <div class="organization-list-wrapper">
          <h3
            class="mb-4"
            v-text="$t('screen.organization.organizationList.title')"
          />
          <sl-vue-tree
            v-model="treeData"
            ref="slVueTree"
            @select="organizationSelect"
          >
            <template slot="title" slot-scope="{ node }">
              <span class="item-icon">
                <i class="fa fa-file" v-if="node.isLeaf"></i>
                <i class="fa fa-folder" v-if="!node.isLeaf"></i>
              </span>

              {{ node.title }}
            </template>

            <template slot="toggle" slot-scope="{ node }">
              <span v-if="!node.isLeaf">
                <i v-if="node.isExpanded" class="fa fa-chevron-down"></i>
                <i v-if="!node.isExpanded" class="fa fa-chevron-right"></i>
              </span>
            </template>
          </sl-vue-tree>

          <div class="mt-2">
            <form
              ref="form"
              @submit.stop.prevent="filterOrganizationByEmailModal"
              class="mb-2 flex-row"
            >
              <b-input-group>
                <b-form-input
                  v-model="filterOrganizationByEmail"
                  :placeholder="
                    $t(
                      'screen.organization.searchOrganizationByEmail.inputPlaceholder'
                    )
                  "
                />
                <b-input-group-append>
                  <b-button
                    type="submit"
                    size="sm"
                    variant="success"
                    :disabled="filterOrganizationByEmail.length === 0"
                  >
                    <b-icon icon="search"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </form>
          </div>
        </div>
        <div class="action-wrapper" v-if="selectedOrganization">
          <h3
            class="mb-4"
            v-text="$t('screen.organization.organization.title')"
          />
          <div class="action-buttons-wrapper">
            <div
              style="display: flex; flex-flow: row; align-items: center"
            ></div>

            <div
              class="mb-3"
              style="
                display: flex;
                flex-flow: row;
                align-items: center;
                font-size: 1.2rem;
              "
            >
              <div
                class="flex-size-1"
                v-text="$t('screen.organization.organization.nameLabel')"
              />
              <div
                class="flex-size-3"
                style="font-weight: bold"
                v-text="selectedOrganization.title"
              />
            </div>

            <div
              style="
                display: flex;
                flex-flow: row;
                align-items: center;
                font-size: 1.2rem;
              "
            >
              <div
                class="flex-size-1"
                v-text="$t('screen.organization.organization.actionLabel')"
              />
              <div class="flex-row flex-size-3">
                <b-button
                  variant="success"
                  type="submit"
                  class="mr-2"
                  :title="
                    $t(
                      'screen.organization.organization.newOrganizationButtonHint'
                    )
                  "
                  @click="newOrganizationModal"
                >
                  <b-icon icon="plus"></b-icon>
                </b-button>
                <b-button
                  variant="success"
                  type="submit"
                  class="mr-2"
                  :title="
                    $t(
                      'screen.organization.organization.editOrganizationButtonHint'
                    )
                  "
                  @click="editOrganizationModal"
                >
                  <b-icon icon="pencil"></b-icon>
                </b-button>
                <b-button
                  variant="success"
                  type="submit"
                  :title="
                    $t(
                      'screen.organization.organization.enterOrganizationButtonHint'
                    )
                  "
                  @click="
                    navigateToScreen({
                      id: selectedOrganization.data.id,
                      name: selectedOrganization.title,
                      screenId: selectedOrganization.data.screenId,
                    })
                  "
                >
                  <b-icon icon="door-open"></b-icon>
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="user-list"
          v-if="selectedOrganization && selectedOrganization.data"
        >
          <h2 class="mb-4" v-text="$t('screen.organization.usersList.title')" />
          <div>
            <div class="user-wrapper">
              <b-button
                size="sm"
                @click="inviteUserModal"
                class="mr-2 btn-success"
              >
                <b-icon icon="plus-square"></b-icon>
              </b-button>
              <div v-text="$t('screen.organization.inviteUserButton')" />
            </div>
          </div>
          <div
            v-if="
              selectedOrganization.data.users &&
              selectedOrganization.data.users.length !== 0
            "
          >
            <div v-for="(u, key) in selectedOrganization.data.users" :key="key">
              <div class="user-wrapper">
                <b-button
                  size="sm"
                  @click="removeUserAccess(u, selectedOrganization.data.id)"
                  class="mr-2 btn-danger"
                >
                  <b-icon icon="dash-square"></b-icon>
                </b-button>
                <div v-text="u.email" />
              </div>
            </div>
          </div>
          <div
            v-else
            class="mt-4 text-left"
            v-text="$t('screen.organization.usersList.emptyList')"
          />
        </div>
      </div>
    </b-overlay>

    <template #modal-footer="{}">
      <div></div>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import DailyMenu from "@/components/DailyMenu.vue";
import FullMenu from "@/components/FullMenu.vue";
import firebase from "firebase/app";
import {
  AddUserToOrganizationRequest,
  DeleteUserFromOrganizationRequest,
  FlatOrganization,
  NewOrganizationPayload,
  Organization,
  OrganizationByEmail,
  OrganizationTree,
  RenameOrganization,
  User,
} from "@/types";
import SlVueTree from "sl-vue-tree";
import { checkEmail, findOrganization } from "@/firebaseHelpers";
import Signup from "../components/SignUp.vue";

@Component({
  components: {
    Header,
    DailyMenu,
    FullMenu,
    SlVueTree,
    Signup,
  },
})
export default class Organizations extends Vue {
  @Prop() organization!: Organization[];
  @Prop() isDataReady!: boolean;
  public modalTitle = this.$t("screen.organization.title").toString();

  public treeData: OrganizationTree[] = [];
  public selectedOrganization: OrganizationTree | null = null;

  public newOrganizationName = "";
  public editOrganizationName = "";
  public invitationEmail = "";
  public filterOrganizationByEmail = "";
  private allOrganizationByEmail: OrganizationByEmail = {};
  public filteredOrganization: FlatOrganization[] = [];

  get validationEmail(): boolean | null {
    return checkEmail(this.invitationEmail);
  }

  public newOrganizationModal() {
    this.$root.$emit("bv::show::modal", "modalNewOrganization");
  }

  public editOrganizationModal() {
    this.editOrganizationName = this.selectedOrganization?.title || "";
    this.$root.$emit("bv::show::modal", "modalEditOrganization");
  }

  public inviteUserModal() {
    this.$root.$emit("bv::show::modal", "modalInviteUser");
  }

  public onSubmitNewOrganization() {
    if (!this.newOrganizationName) {
      return;
    }
    if (!this.selectedOrganization) {
      return;
    }

    const payload: NewOrganizationPayload = {
      parentOrganizationId: this.selectedOrganization?.data.id,
      newOrganizationName: this.newOrganizationName,
    };

    this.newOrganizationName = "";
    this.$emit("onNewOrganization", payload);
  }

  public filterOrganizationByEmailModal() {
    this.filteredOrganization = [];
    Object.entries(this.allOrganizationByEmail)
      .filter(([key]) => {
        return key.includes(this.filterOrganizationByEmail);
      })
      .map(([, val]) => val)
      .forEach((o) => this.filteredOrganization.push(...o));

    this.$root.$emit("bv::show::modal", "modalOrganizationByEmail");
    this.filterOrganizationByEmail = "";
  }

  public organizationSelect(nodes: OrganizationTree[]) {
    this.selectedOrganization = nodes[0];
  }

  public onSubmitEditOrganization() {
    if (!this.selectedOrganization || !this.editOrganizationName) {
      return;
    }

    const payload: RenameOrganization = {
      organizationId: this.selectedOrganization.data.id,
      name: this.editOrganizationName,
    };

    this.$emit("renameSelectedOrganization", payload);
    this.selectedOrganization.title = this.editOrganizationName;
    this.editOrganizationName = "";
  }

  public removeUserAccess(user: User, organizationId: string) {
    const payload: DeleteUserFromOrganizationRequest = {
      user: user,
      organizationId,
    };
    this.$emit("removeUserAccess", payload);
  }

  public inviteUserToOrganization() {
    if (!this.selectedOrganization || !this.invitationEmail) {
      console.error("no selected organization");
      return;
    }

    const payload: AddUserToOrganizationRequest = {
      organizationId: this.selectedOrganization.data.id,
      user: {
        email: this.invitationEmail,
        password: "",
      },
    };
    this.$emit("inviteUserToOrganization", payload);
    this.invitationEmail = "";
  }

  mounted(): void {
    const auth = firebase.auth();
    if (!auth.currentUser) {
      return;
    }
  }

  @Watch("$i18n.locale", { immediate: true, deep: true })
  private languageChange(): void {
    this.modalTitle = this.$t("screen.organization.title").toString();
  }

  @Watch("organization", { immediate: true, deep: true })
  private organizationChanged() {
    if (!this.organization) {
      return;
    }

    this.treeData = this.buildTreeData(this.organization);
    if (this.selectedOrganization && this.selectedOrganization.data.id) {
      const o = findOrganization(
        this.selectedOrganization?.data.id,
        this.organization
      );
      this.$set(this.selectedOrganization.data, "screenId", o?.screens[0]);
      this.$set(this.selectedOrganization.data, "users", o?.users);
    }

    if (!this.selectedOrganization && this.treeData[0]) {
      this.selectedOrganization = { ...this.treeData[0] };
    }

    this.selectedOrganization = !this.selectedOrganization
      ? this.treeData[0]
      : this.selectedOrganization;

    this.allOrganizationByEmail = this.getAllOrganizationByEmail(
      this.organization
    );
  }

  private getAllOrganizationByEmail(
    organization: Organization[]
  ): OrganizationByEmail {
    const organizationByEmail: OrganizationByEmail = {};
    organization.forEach((o) => {
      for (const u of o.users || []) {
        if (!organizationByEmail[u.email]) {
          organizationByEmail[u.email] = [];
        }

        organizationByEmail[u.email].push({
          id: o.id,
          name: o.name,
          screenId: o.screens[0].id,
        });
      }

      let obe: OrganizationByEmail = {};
      if (o.organization?.length !== 0) {
        obe = this.getAllOrganizationByEmail(o.organization || []);
        Object.entries(obe).forEach(([key, value]) => {
          if (!organizationByEmail[key]) {
            organizationByEmail[key] = [];
          }

          organizationByEmail[key].push(...value);
        });
      }
    });

    return organizationByEmail;
  }

  public navigateToScreen(o: FlatOrganization) {
    (this.$refs.modalGroups as any).hide();
    this.$emit("onChangeOrganization", o);
  }

  private buildTreeData(organization: Organization[]): OrganizationTree[] {
    return organization.map((o) => {
      let children: OrganizationTree[] = [];

      if (o.organization && organization.length !== 0) {
        children = this.buildTreeData(o.organization);
      }
      let screenId = "";
      if (o.screens) {
        screenId = o.screens[0].id;
      }

      return {
        title: o.name,
        isDraggable: false,
        children,
        data: {
          id: o.id,
          screenId,
          users: o.users,
        },
      };
    });
  }
}
</script>

<style lang="less">
@import "../layout.less";

a {
  color: #bbb;
}

.row {
  display: flex;
  margin-bottom: 10px;
}

.contextmenu {
  position: absolute;
  background-color: white;
  color: black;
  border-radius: 2px;
  cursor: pointer;
}

.contextmenu > div {
  padding: 10px;
}

.contextmenu > div:hover {
  background-color: rgba(100, 100, 255, 0.5);
}

.last-event {
  color: white;
  background-color: rgba(100, 100, 255, 0.5);
  padding: 10px;
  border-radius: 2px;
}

.tree-container {
  flex-grow: 1;
}

.sl-vue-tree.sl-vue-tree-root {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.item-icon {
  display: inline-block;
  text-align: left;
  width: 20px;
}

.sl-vue-tree {
  position: relative;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.sl-vue-tree.sl-vue-tree-root {
  color: rgb(9, 22, 29);
}

.sl-vue-tree-root > .sl-vue-tree-nodes-list {
  overflow: hidden;
  position: relative;
  padding-bottom: 4px;
}

.sl-vue-tree-selected > .sl-vue-tree-node-item {
  color: rgb(116, 116, 116);
}

.sl-vue-tree-node-item:hover,
.sl-vue-tree-node-item.sl-vue-tree-cursor-hover {
  color: rgb(116, 116, 116);
}

.sl-vue-tree-node-item {
  position: relative;
  display: flex;
  flex-direction: row;

  padding-left: 10px;
  padding-right: 10px;
  line-height: 28px;
  border: 1px solid transparent;
}

.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.sl-vue-tree-gap {
  width: 25px;
  min-height: 1px;
}

.sl-vue-tree-toggle {
  display: inline-block;
  text-align: left;
  width: 20px;
}

.sl-vue-tree-cursor {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.5);
  height: 1px;
  width: 100%;
}

.sl-vue-tree-drag-info {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  margin-left: 20px;
  padding: 5px 10px;
}

.modal-organization-by-email-wrapper {
  .flex-column();

  .organization {
    .flex-row();
    .vertical-center();

    .name {
      flex: 3;
      font-size: 1.1rem;
    }
    .navigate {
      flex: 1;
      margin: 0.5rem;
    }
  }
}

.action-btn {
  white-space: pre-line;
}

.organization-wrapper {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  padding-left: 1rem;
  text-align: left;
}

.organization-list-wrapper {
  flex: 1;
  padding-right: 1.5rem;
}

.user-list {
  flex: 1;
  padding-left: 1.5rem;
  border-left: 1px solid lightgray;
}

.user-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 0.2rem;
}

.action-buttons-wrapper {
  padding: 0.5rem 0.5rem 0.5rem 0;
  .flex-column();
}

.action-wrapper {
  .flex-column();
  flex: 1;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-left: 1px solid lightgray;
}
</style>
