import firebase from "firebase/app";
import VueRouter from "vue-router";
import { defaultValues } from "./defaultValues";
import {
  Screen,
  NewUser,
  Organization,
  User,
  NewRegistrationRequest,
  NewRegistrationResponse,
  AddUserToOrganizationRequest,
  DeleteUserFromOrganizationRequest,
  RenameOrganization,
  Lang,
} from "./types";
import { emptyScreen } from "./mapper";

function getDataOnce<T>(ref: firebase.database.Reference): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const onError = (error) => reject(error);
    const onData = (snap) => resolve(snap.val());

    ref.once("value", onData, onError);
  });
}

function findOrganization(
  organizationId: string,
  organization: Organization[]
): Organization | null {
  for (const o of organization) {
    if (o.id === organizationId) {
      return o;
    }
    if (!o.organization || o.organization.length === 0) {
      continue;
    }

    const foundOrganization = findOrganization(organizationId, o.organization);
    if (foundOrganization) {
      return foundOrganization;
    }
  }

  return null;
}

async function newScreen(name: string, lang: Lang): Promise<Screen | null> {
  const defaultValuesLang = defaultValues[lang];
  if (!defaultValuesLang) {
    return null;
  }

  const db = firebase.database();

  const dailyMenu = db.ref(`dailyMenu/`);
  const refDailyMenu = dailyMenu.push();
  if (!refDailyMenu.key) {
    return null;
  }
  await refDailyMenu.set({
    active: true,
  });

  const fullMenu = db.ref(`fullMenu/`);
  const refFullMenu = fullMenu.push();
  if (!refFullMenu.key) {
    return null;
  }
  await refFullMenu.set({
    active: true,
  });

  const screens = db.ref(`screens/`);
  const refScreen = screens.push();
  if (!refScreen.key) {
    // TODO: mozna nejaky error
    return null;
  }

  const screen = {
    ...emptyScreen(lang),
    dailyMenuId: refDailyMenu.key,
    fullMenuId: refFullMenu.key,
    disableTitle: false,
    disableImage: false,
    name,
  };

  await refScreen.set(screen);

  return {
    ...screen,
    id: refScreen.key,
  };
}

async function newOrganization(
  name: string,
  screens: Screen[]
): Promise<string | null> {
  const db = firebase.database();

  const organization = db.ref(`organization/`);
  const refOrganization = organization.push();
  if (!refOrganization.key) {
    // TODO: mozna nejaky error
    return null;
  }

  await refOrganization.set({
    name,
    screens: screens.reduce((acc, curr) => {
      acc[curr.id] = true;
      return acc;
    }, {}),
  });

  return refOrganization.key;
}

async function newUser(
  u: NewUser,
  additionalInformation?: any
): Promise<User | null> {
  const userCredential = await firebase
    .auth()
    .createUserWithEmailAndPassword(u.email, u.password);

  const user = userCredential.user;
  if (!user) {
    return null;
  }

  const db = firebase.database();
  await db.ref(`users/${user.uid}/`).set({
    email: u.email,
    ...additionalInformation,
  });

  return {
    email: u.email,
    uid: user.uid,
  };
}

async function registerUser(
  payload: NewRegistrationRequest
): Promise<NewRegistrationResponse | null> {
  return fetch("/api/v1/registerUser", {
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then(async (res) => {
      if (!res.ok) {
        throw new Error(await res.text());
      }

      return await res.json();
    })
    .catch((err) => {
      throw err;
    });
}

export async function addUserToOrganization(
  payload: AddUserToOrganizationRequest,
  token: string
): Promise<User | null> {
  return fetch("/api/v1/addUserToOrganization", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then(async (res) => res.json())
    .catch((err) => {
      throw err;
    });
}

export async function renameOrganization(
  payload: RenameOrganization
): Promise<void> {
  const db = firebase.database();
  if (!payload.organizationId) {
    throw new Error(
      `"${payload.organizationId}" is invalid value for organization`
    );
  }

  const organization = db.ref(`organization/${payload.organizationId}`);
  await organization.update({ name: payload.name });
}

export async function deleteUserFromOrganization(
  payload: DeleteUserFromOrganizationRequest,
  token: string
): Promise<void> {
  await fetch("/api/v1/addUserToOrganization", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
}

function getSignInUrl(router: VueRouter): string {
  return new URL(router.resolve("/signin").href, window.location.origin).href;
}

function checkEmail(email: string): boolean | null {
  return Boolean(
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
    )
  );
}

export {
  getDataOnce,
  newOrganization,
  findOrganization,
  newUser,
  getSignInUrl,
  registerUser,
  newScreen,
  checkEmail,
};
