export interface Meal {
  name: string;
  price: string;
  soldOut?: boolean;
}

export interface SelectedMeal extends Meal {
  available: boolean;
  order: number;
  currency?: string;
}

export type SelectedMeals = { [id: string]: SelectedMeal };
export type AllMeals = { [id: string]: Meal };

export interface TableColumn {
  key: string;
  label: string;
  sortable?: boolean;
  sortDirection?: string;
  class?: string;
}

export interface OrganizationDbModel {
  id: string;
  name?: string;
  organization?: { [organizationId: string]: boolean };
  screens?: { [screenIdId: string]: boolean };
  users?: { [userUid: string]: boolean };
}

export interface Organization {
  id: string;
  name: string;
  organization: Organization[];
  screens: Screen[];
  users?: User[];
}

export interface OrganizationTree {
  title: string;
  children?: OrganizationTree[];
  isDraggable: boolean;
  path?: number[];
  data: {
    id: string;
    screenId: string;
    users?: User[];
  };
}

export enum MenuSourceType {
  Default = "default",
  Adaptee = "adaptee",
}

export interface ScreenDbModel {
  id: string;
  name: string;
  headerText: string;
  disableTitle: boolean | string;
  disableImage: boolean | string;
  screenScale: number;
  noMenuTitle: string;
  fullMenuId: string;
  dailyMenuId: string;
  currency: string;
  color: string;
  backgroundImagePortrait: BackgroundImage;
  backgroundImageLandscape: BackgroundImage;
  usersId?: string[];
  menuSourceUrl: string;
  menuSourceType: MenuSourceType;
  topBoldCount: number;
}

export interface Screen {
  id: string;
  name: string;
  headerText: string;
  disableTitle: boolean;
  disableImage: boolean;
  screenScale: number;
  noMenuTitle: string;
  fullMenuId: string;
  dailyMenuId: string;
  currency: string;
  color: string;
  backgroundImageLandscape: string;
  backgroundImagePortrait: string;
  usersId?: string[];
  menuSourceUrl?: string;
  menuSourceType?: MenuSourceType;
  topBoldCount: number;
}

export interface BackgroundImage {
  generation: string;
  path: string;
}

export interface ScreenSettings {
  name: string;
  color: string;
  currency: string;
  noMenuTitle: string;
  headerText: string;
  disableTitle: boolean;
  disableImage: boolean;
  screenScale: number;
  backgroundImagePortrait?: File;
  backgroundImageLandscape?: File;
  topBoldCount: number;
  menuSourceUrl: string;
  menuSourceType: MenuSourceType;
}

export interface NewOrganizationPayload {
  parentOrganizationId: string;
  newOrganizationName: string;
}

export interface NewUser {
  email: string;
  password: string;
  organizationName: string;
  lang: string;
}

export interface User {
  uid: string;
  email: string;
}

export interface AddUserToOrganizationRequest {
  organizationId: string;
  user: {
    email: string;
    password: string;
  };
}

export interface DeleteUserFromOrganizationRequest {
  organizationId: string;
  user: {
    uid: string;
    email: string;
  };
}

export interface RenameOrganization {
  organizationId: string;
  name: string;
}

export interface NewRegistrationRequest {
  parentOrganizationId: string;
  lang: string;
  user: {
    email: string;
    password: string;
  };
  organizationName: string;
}

export interface NewRegistrationResponse {
  user: {
    uid: string;
    email: string;
  };
  organization: {
    id: string;
    name: string;
  };
  token: string;
}

export interface FlatOrganization {
  id: string;
  name: string;
  screenId: string;
}

export type OrganizationByEmail = { [email: string]: FlatOrganization[] };

export type Lang = "cs" | "en";
