<template>
  <b-overlay class="root" :show="!isReady" rounded="lg" :opacity="1">
    <div v-if="!isPortrait && isReady" class="menu">
      <div class="text" v-if="isReady && menu.length === 0">
        <div
          v-if="!screen.disableImage"
          class="flex-27 side-panel"
          :style="[
            { 'border-right-color': screen.color },
            screen.backgroundImageLandscape
              ? {
                  'background-image':
                    'url(' + screen.backgroundImageLandscape + ')',
                }
              : '',
          ]"
        ></div>
        <div class="flex-165 no-meal">
          <div v-text="screen.noMenuTitle"></div>
        </div>
      </div>
      <div v-else-if="isReady && menu.length > 0" class="meals">
        <div
          v-if="!screen.disableImage"
          class="flex-27 side-panel"
          :style="[
            { 'border-right-color': screen.color },
            screen.backgroundImageLandscape
              ? {
                  'background-image':
                    'url(' + screen.backgroundImageLandscape + ')',
                }
              : '',
          ]"
        ></div>
        <div class="flex-165" style="display: flex; flex-flow: column">
          <div
            class="header-text-wrapper"
            v-if="screen.headerText && !screen.disableTitle"
            :style="{ color: screen.color }"
          >
            <span class="header-text" v-text="screen.headerText"></span>
            <span class="date" v-text="currentDate"> </span>
            <span class="time" v-text="currentTime"></span>
          </div>
          <div class="daily-menu">
            <div
              v-for="(f, i) in menu"
              :key="i"
              :style="{
                'font-weight': i < screen.topBoldCount ? '600' : 'normal',
              }"
            >
              <div
                class="meal"
                :style="{
                  'text-decoration': f.soldOut ? 'line-through' : 'none',
                }"
              >
                <div
                  class="number"
                  :style="{ 'background-color': screen.color }"
                >
                  <div v-text="f.order"></div>
                </div>
                <div
                  class="detail"
                  :style="{ 'border-bottom-color': mealDelimiterColor }"
                >
                  <div v-text="f.name"></div>
                  <div
                    v-if="f.currency === 'czk'"
                    class="price"
                    :style="{ color: screen.color }"
                    v-text="f.price + ',-'"
                  ></div>
                  <div
                    v-else-if="f.currency === 'eur'"
                    class="price"
                    :style="{ color: screen.color }"
                    v-text="'€ ' + f.price"
                  ></div>
                  <div
                    v-else-if="f.currency === 'usd'"
                    class="price"
                    :style="{ color: screen.color }"
                    v-text="'$ ' + f.price"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isPortrait && isReady" class="menu">
      <div
        v-if="!screen.disableImage"
        class="top-panel"
        :style="
          screen.backgroundImagePortrait
            ? {
                'background-image':
                  'url(' + screen.backgroundImagePortrait + ')',
              }
            : ''
        "
      ></div>
      <div
        v-if="screen.headerText && !screen.disableTitle"
        class="header-text"
        :style="{
          'background-color': screen.color,
        }"
      >
        <div v-text="screen.headerText"></div>
        <div class="data-time-wrapper">
          <span class="date" v-text="currentDate"> </span>
          <span class="time" v-text="currentTime"></span>
        </div>
      </div>

      <div class="daily-menu">
        <div class="no-meal" v-if="isReady && menu.length === 0">
          <div v-text="screen.noMenuTitle" />
        </div>
        <div v-else-if="isReady && menu.length > 0" class="meals">
          <div
            v-for="(f, i) in menu"
            :key="i"
            :style="{
              'font-weight': i < screen.topBoldCount ? '600' : 'normal',
            }"
          >
            <div class="meal">
              <div class="number" :style="{ 'background-color': screen.color }">
                <div v-text="f.order"></div>
              </div>
              <div
                class="detail"
                :style="{ 'border-bottom-color': mealDelimiterColor }"
              >
                <div v-text="f.name"></div>
                <div
                  v-if="f.currency === 'czk'"
                  class="price"
                  :style="{ color: screen.color }"
                  v-text="f.price + ',-'"
                ></div>
                <div
                  v-else-if="f.currency === 'eur'"
                  class="price"
                  :style="{ color: screen.color }"
                  v-text="'€ ' + f.price"
                ></div>
                <div
                  v-else-if="f.currency === 'usd'"
                  class="price"
                  :style="{ color: screen.color }"
                  v-text="'$ ' + f.price"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { SelectedMeal, Screen } from "../types";
import moment from "moment";
import tinycolor from "tinycolor2";

@Component({})
export default class Menu extends Vue {
  @Prop() menu!: SelectedMeal[];
  @Prop() screen!: Screen;
  @Prop() isReady!: boolean;

  public currentTime = "";
  public currentDate = "";
  public isPortrait = false;

  get mealDelimiterColor() {
    return tinycolor(this.screen.color).setAlpha(0.3).toRgbString();
  }

  constructor() {
    super();
    this.isPortrait = window.innerHeight > window.innerWidth;
    window.addEventListener("resize", this.onresize);
  }

  destroyed() {
    window.removeEventListener("resize", this.onresize);
  }

  mounted() {
    this.updateTime();
  }

  private onresize() {
    this.isPortrait = window.innerHeight > window.innerWidth;
  }

  private lastUpdateMinute = -1;
  private updateTime() {
    const now = moment();
    if (now.minute() === this.lastUpdateMinute) {
      setTimeout(this.updateTime, 1000);
      return;
    }
    this.lastUpdateMinute = now.minute();
    this.currentTime = moment().format("HH:mm");
    this.currentDate = moment().format("D. M. YYYY");
    setTimeout(this.updateTime, 1000);
  }
}
</script>

<style lang="less" scoped>
@import "../layout.less";

.daily-menu {
  scrollbar-width: none;
}

.daily-menu {
  overflow: auto;
}

/* width */
::-webkit-scrollbar {
  width: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.root {
  .full-screen();
  user-select: none;
}

@media (orientation: landscape) {
  .menu {
    .full-screen();

    .side-panel {
      border-right: 0.25rem solid;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: left;
    }

    .header-text-wrapper {
      padding-left: 0.65rem;
      padding-top: 0.4rem;
      padding-right: 0.56rem;

      .flex-row();
      text-align: left;
      margin-bottom: 0.45rem;
      align-items: baseline;

      .header-text {
        font-weight: bold;
        font-size: 0.6rem;
        text-align: left;
        flex: 1;
      }

      .time {
        font-size: 0.7rem;
      }

      .date {
        font-size: 0.45rem;
        padding-right: 0.3rem;
      }
    }

    .meals {
      .flex-row();
      .full-screen();

      .daily-menu {
        background-color: #fff;
        padding-top: 0.4rem;
        padding-left: 0.65rem;
        padding-right: 0.56rem;
      }
    }

    .text {
      .full-screen();
      .flex-row();
      .vertical-center();
      .horizontal-center();

      .no-meal {
        font-size: 1.5rem;
        background-color: #fff;

        .full-screen();
        .flex-column();
        .vertical-center();
        .horizontal-center();

        div {
          width: 95%;
          margin: 0 auto;
        }
      }
    }
  }

  .meal {
    .flex-row();
    .vertical-center();
    height: 100%;

    .number {
      .flex-column();
      .vertical-center();
      .horizontal-center();

      border-radius: 50%;
      color: #ffffff;
      font-weight: 600;
      font-size: 0.25rem;

      width: 0.5rem;
      height: 0.5rem;
    }

    .detail {
      color: #000000;
      flex: 1;
      margin-left: 0.25rem;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.032rem solid;
      font-size: 0.4rem;
      height: 100%;
      text-align: left;

      div:first-child {
        flex: 1;
      }

      .price {
        font-weight: 600;
      }
    }
  }
}

@media (min-width: 640px) and (orientation: landscape) {
  .menu {
    .side-panel {
      border-right: 0.5rem solid;
    }

    .header-text-wrapper {
      padding-left: 1.3rem;
      padding-top: 0.8rem;
      padding-right: 1.125rem;
      margin-bottom: 0.9rem;

      .header-text {
        font-size: 1.2rem;
      }

      .time {
        font-size: 1.4rem;
      }

      .date {
        font-size: 0.9rem;
        padding-right: 0.6rem;
      }
    }

    .meals {
      .daily-menu {
        padding-top: 0.8rem;
        padding-left: 1.3rem;
        padding-right: 1.125rem;
      }
    }

    .text {
      .no-meal {
        font-size: 2rem;
      }
    }
  }

  .meal {
    .number {
      font-size: 0.5rem;

      width: 1rem;
      height: 1rem;
    }

    .detail {
      margin-left: 0.5rem;
      border-bottom: 0.063rem solid;
      font-size: 0.8rem;
    }
  }
}

@media (min-width: 768px) and (orientation: landscape) {
  .menu {
    .side-panel {
      border-right: 0.6rem solid;
    }

    .header-text-wrapper {
      padding-left: 1.56rem;
      padding-top: 0.96rem;
      padding-right: 1.35rem;

      margin-bottom: 1.08rem;

      .header-text {
        font-size: 1.44rem;
      }

      .time {
        font-size: 1.68rem;
      }

      .date {
        font-size: 1.08rem;
        padding-right: 0.72rem;
      }
    }

    .meals {
      .daily-menu {
        padding-top: 0.96rem;
        padding-left: 1.56rem;
        padding-right: 1.35rem;
      }
    }

    .text {
      .no-meal {
        font-size: 2.5rem;
      }
    }
  }

  .meal {
    .number {
      font-size: 0.6rem;

      width: 1.2rem;
      height: 1.2rem;
    }

    .detail {
      margin-left: 0.6rem;
      border-bottom: 0.0756rem solid;
      font-size: 0.96rem;
    }
  }
}

@media (min-width: 1024px) and (orientation: landscape) {
  .menu {
    .side-panel {
      border-right: 0.8rem solid;
    }

    .header-text-wrapper {
      padding-left: 2.08rem;
      padding-top: 1.28rem;
      padding-right: 1.8rem;

      margin-bottom: 1.44rem;

      .header-text {
        font-size: 1.92rem;
      }

      .time {
        font-size: 2.24rem;
      }

      .date {
        font-size: 1.44rem;
        padding-right: 0.96rem;
      }
    }

    .meals {
      .daily-menu {
        padding-top: 1.28rem;
        padding-left: 2.08rem;
        padding-right: 1.8rem;
      }
    }

    .text {
      .no-meal {
        font-size: 3rem;
      }
    }
  }

  .meal {
    .number {
      font-size: 0.8rem;

      width: 1.6rem;
      height: 1.6rem;
    }

    .detail {
      margin-left: 0.8rem;
      border-bottom: 0.1008rem solid;
      font-size: 1.28rem;
    }
  }
}

@media (min-width: 1280px) and (orientation: landscape) {
  .menu {
    .side-panel {
      border-right: 1rem solid;
    }

    .header-text-wrapper {
      padding-left: 2.6rem;
      padding-top: 1.6rem;
      padding-right: 2.25rem;

      margin-bottom: 1.8rem;

      .header-text {
        font-size: 2.4rem;
      }

      .time {
        font-size: 2.8rem;
      }

      .date {
        font-size: 1.8rem;
        padding-right: 1.2rem;
      }
    }

    .meals {
      .daily-menu {
        padding-top: 1.6rem;
        padding-left: 2.6rem;
        padding-right: 2.25rem;
      }
    }

    .text {
      .no-meal {
        font-size: 3.5rem;
      }
    }
  }

  .meal {
    .number {
      font-size: 1rem;

      width: 2rem;
      height: 2rem;
    }

    .detail {
      margin-left: 1rem;
      border-bottom: 0.125rem solid;
      font-size: 1.6rem;
    }
  }
}

@media (min-width: 1536px) and (orientation: landscape) {
  .menu {
    .side-panel {
      border-right: 1.2rem solid;
    }

    .header-text-wrapper {
      padding-left: 3.12rem;
      padding-top: 1.92rem;
      padding-right: 2.7rem;

      margin-bottom: 2.16rem;

      .header-text {
        font-size: 2.88rem;
      }

      .time {
        font-size: 3.36rem;
      }

      .date {
        font-size: 2.16rem;
        padding-right: 1.44rem;
      }
    }

    .meals {
      .daily-menu {
        padding-top: 1.92rem;
        padding-left: 3.12rem;
        padding-right: 2.7rem;
      }
    }

    .text {
      .no-meal {
        font-size: 4rem;
      }
    }
  }

  .meal {
    .number {
      font-size: 1.2rem;

      width: 2.4rem;
      height: 2.4rem;
    }

    .detail {
      margin-left: 1.2rem;
      border-bottom: 0.1512rem solid;
      font-size: 1.92rem;
    }
  }
}

@media (min-width: 1920px) and (orientation: landscape) {
  .menu {
    .side-panel {
      border-right: 1.5rem solid;
    }

    .header-text-wrapper {
      padding-left: 3rem;
      padding-top: 2.4rem;
      padding-right: 3.375rem;

      margin-bottom: 3.4rem;

      .header-text {
        font-size: 3.6rem;
      }

      .time {
        font-size: 4.2rem;
      }

      .date {
        font-size: 2.7rem;
        padding-right: 1.8rem;
      }
    }

    .meals {
      .daily-menu {
        padding-top: 2.4rem;
        padding-left: 3rem;
        padding-right: 3.375rem;
      }
    }

    .text {
      .no-meal {
        font-size: 4.5rem;
      }
    }
  }

  .meal {
    .number {
      font-size: 1.3rem;

      width: 2.8rem;
      height: 2.8rem;
    }

    .detail {
      margin-left: 1.5rem;
      border-bottom: 0.125rem solid;
      font-size: 2.2rem;
    }
  }
}

@media (min-width: 2560px) and (orientation: landscape) {
  .menu {
    .side-panel {
      border-right: 1.995rem solid;
    }

    .header-text-wrapper {
      padding-left: 3.99rem;
      padding-top: 3.192rem;
      padding-right: 4.48875rem;

      margin-bottom: 4.522rem;

      .header-text {
        font-size: 4.788rem;
      }

      .time {
        font-size: 5.586rem;
      }

      .date {
        font-size: 3.591rem;
        padding-right: 2.394rem;
      }
    }

    .meals {
      .daily-menu {
        padding-top: 3.192rem;
        padding-left: 3.99rem;
        padding-right: 4.48875rem;
      }
    }

    .text {
      .no-meal {
        font-size: 6rem;
      }
    }
  }

  .meal {
    .number {
      font-size: 1.729rem;

      width: 3.724rem;
      height: 3.724rem;
    }

    .detail {
      margin-left: 1.995rem;
      border-bottom: 0.16625rem solid;
      font-size: 2.926rem;
    }
  }
}

@media (min-width: 3840px) and (orientation: landscape) {
  .menu {
    .side-panel {
      border-right: 3rem solid;
    }

    .header-text-wrapper {
      padding-left: 6rem;
      padding-top: 4.8rem;
      padding-right: 6.75rem;

      margin-bottom: 6.8rem;

      .header-text {
        font-size: 7.2rem;
      }

      .time {
        font-size: 8.4rem;
      }

      .date {
        font-size: 5.4rem;
        padding-right: 3.6rem;
      }
    }

    .meals {
      .daily-menu {
        padding-top: 4.8rem;
        padding-left: 6rem;
        padding-right: 6.75rem;
      }
    }

    .text {
      .no-meal {
        font-size: 9rem;
      }
    }
  }

  .meal {
    .number {
      font-size: 2.6rem;

      width: 5.6rem;
      height: 5.6rem;
    }

    .detail {
      margin-left: 3rem;
      border-bottom: 0.25rem solid;
      font-size: 4.4rem;
    }
  }
}

@media screen and (orientation: portrait) {
  .menu {
    .full-screen();
    .flex-column();

    .top-panel {
      height: 4.62rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
    }

    .header-text {
      .flex-row();
      .vertical-center();
      .horizontal-space-between();
      height: 1.87rem;
      color: #fff;
      font-size: 0.616rem;
      font-weight: bold;
      padding-left: 0.77rem;
      margin-bottom: 0.22rem;
      text-align: left;

      div:first-child {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .data-time-wrapper {
        flex: 1;
        .flex-row();
        .horizontal-end();
        height: 1.43rem;
        align-items: baseline;
        color: #fff;
        padding-right: 0.374rem;
        font-weight: bold;

        .date {
          font-size: 0.374rem;
          padding-right: 0.33rem;
        }

        .time {
          font-size: 0.924rem;
          color: #fff;
        }
      }
    }

    .daily-menu {
      flex: 1;

      .no-meal {
        .full-screen();
        .flex-row();
        .vertical-center();
        .horizontal-center();
        font-size: 1rem;
        background-color: #fff;

        div {
          width: 95%;
          margin: 0 auto;
        }
      }
    }

    .meals {
      .flex-column();
      .full-screen();

      background-color: #fff;
      padding-left: 0.77rem;
      padding-top: 0.77rem;
      padding-right: 0.77rem;
      padding-bottom: 0.506rem;
    }
  }

  .meal {
    .flex-row();
    .vertical-center();
    height: 100%;

    .number {
      .flex-column();
      .vertical-center();
      .horizontal-center();

      border-radius: 50%;
      color: #ffffff;
      font-weight: 600;
      font-size: 0.385rem;

      width: 0.77rem;
      height: 0.77rem;
    }

    .detail {
      color: #000000;
      flex: 1;
      margin-left: 0.22rem;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.00704rem solid;
      font-size: 0.385rem;
      height: 100%;
      text-align: left;

      div:first-child {
        flex: 1;
      }

      .price {
        font-weight: 600;
      }
    }
  }
}

@media (min-width: 320px) and (orientation: portrait) {
  .menu {
    .top-panel {
      height: 6.3rem;
    }

    .header-text {
      height: 2.55rem;
      font-size: 0.84rem;
      padding-left: 1.05rem;
      margin-bottom: 0.3rem;

      .data-time-wrapper {
        height: 1.95rem;
        padding-right: 0.51rem;

        .date {
          font-size: 0.51rem;
          padding-right: 0.45rem;
        }

        .time {
          font-size: 1.26rem;
        }
      }
    }

    .daily-menu {
      .no-meal {
        font-size: 1.5rem;
      }
    }

    .meals {
      padding-left: 1.05rem;
      padding-top: 1.05rem;
      padding-right: 1.05rem;
      padding-bottom: 0.69rem;
    }
  }

  .meal {
    padding-bottom: 0.29rem;

    .number {
      font-size: 0.525rem;

      width: 1.05rem;
      height: 1.05rem;
    }

    .detail {
      margin-left: 0.3rem;
      border-bottom: 0.0096rem solid;
      font-size: 0.525rem;
    }
  }
}

@media (min-width: 432px) and (orientation: portrait) {
  .menu {
    .top-panel {
      height: 8.4rem;
    }

    .header-text {
      height: 3.4rem;
      font-size: 1.12rem;
      padding-left: 1.4rem;
      margin-bottom: 0.4rem;

      .data-time-wrapper {
        height: 2.6rem;
        padding-right: 0.68rem;

        .date {
          font-size: 0.68rem;
          padding-right: 0.6rem;
        }

        .time {
          font-size: 1.68rem;
        }
      }
    }

    .daily-menu {
      .no-meal {
        font-size: 2rem;
      }
    }

    .meals {
      padding-left: 1.4rem;
      padding-top: 1.4rem;
      padding-right: 1.4rem;
      padding-bottom: 0.92rem;
    }
  }

  .meal {
    padding-bottom: 0.4rem;

    .number {
      font-size: 0.7rem;

      width: 1.4rem;
      height: 1.4rem;
    }

    .detail {
      margin-left: 0.4rem;
      border-bottom: 0.0128rem solid;
      font-size: 0.7rem;
    }
  }
}

@media (min-width: 576px) and (orientation: portrait) {
  .menu {
    .top-panel {
      height: 11.13rem;
    }

    .header-text {
      height: 4.505rem;
      font-size: 1.484rem;
      padding-left: 1.855rem;
      margin-bottom: 0.53rem;

      .data-time-wrapper {
        height: 3.445rem;
        padding-right: 0.901rem;

        .date {
          font-size: 0.901rem;
          padding-right: 0.795rem;
        }

        .time {
          font-size: 2.226rem;
        }
      }
    }

    .daily-menu {
      .no-meal {
        font-size: 2.5rem;
      }
    }

    .meals {
      padding-left: 1.855rem;
      padding-top: 1.855rem;
      padding-right: 1.855rem;
      padding-bottom: 1.219rem;
    }
  }

  .meal {
    padding-bottom: 0.53rem;

    .number {
      font-size: 0.9275rem;

      width: 1.855rem;
      height: 1.855rem;
    }

    .detail {
      margin-left: 0.53rem;
      border-bottom: 0.01696rem solid;
      font-size: 0.9275rem;
    }
  }
}

@media (min-width: 720px) and (orientation: portrait) {
  .menu {
    .top-panel {
      height: 13.86rem;
    }

    .header-text {
      height: 5.61rem;
      font-size: 1.848rem;
      padding-left: 2.31rem;
      margin-bottom: 0.66rem;

      .data-time-wrapper {
        height: 4.29rem;
        padding-right: 1.122rem;

        .date {
          font-size: 1.122rem;
          padding-right: 0.99rem;
        }

        .time {
          font-size: 2.772rem;
        }
      }
    }

    .daily-menu {
      .no-meal {
        font-size: 3rem;
      }
    }

    .meals {
      padding-left: 2.31rem;
      padding-top: 2.31rem;
      padding-right: 2.31rem;
      padding-bottom: 1.518rem;
    }
  }

  .meal {
    padding-bottom: 0.75;

    .number {
      font-size: 1.155rem;

      width: 2.31rem;
      height: 2.31rem;
    }

    .detail {
      margin-left: 0.66rem;
      border-bottom: 0.02112rem solid;
      font-size: 1.155rem;
    }
  }
}

@media (min-width: 864px) and (orientation: portrait) {
  .menu {
    .top-panel {
      height: 16.8rem;
    }

    .header-text {
      height: 6.8rem;
      font-size: 2.24rem;
      padding-left: 2.8rem;
      margin-bottom: 0.8rem;

      .data-time-wrapper {
        height: 5.2rem;
        padding-right: 1.36rem;

        .date {
          font-size: 1.36rem;
          padding-right: 1.2rem;
        }

        .time {
          font-size: 3.36rem;
        }
      }
    }

    .daily-menu {
      .no-meal {
        font-size: 3.5rem;
      }
    }

    .meals {
      padding-left: 2.8rem;
      padding-top: 2.8rem;
      padding-right: 2.8rem;
      padding-bottom: 1.84rem;
    }
  }

  .meal {
    padding-bottom: 0.8rem;

    .number {
      font-size: 1.4rem;

      width: 2.8rem;
      height: 2.8rem;
    }

    .detail {
      margin-left: 0.8rem;
      border-bottom: 0.0256rem solid;
      font-size: 1.4rem;
    }
  }
}

@media (min-width: 1080px) and (orientation: portrait) {
  .menu {
    .top-panel {
      height: 21rem;
    }

    .header-text {
      height: 8.5rem;
      font-size: 2.8rem;
      padding-left: 3.5rem;
      margin-bottom: 1rem;

      .data-time-wrapper {
        height: 6.5rem;
        padding-right: 1.7rem;

        .date {
          font-size: 1.7rem;
          padding-right: 1.5rem;
        }

        .time {
          font-size: 4.2rem;
        }
      }
    }

    .daily-menu {
      .no-meal {
        font-size: 5rem;
      }
    }

    .meals {
      padding-left: 3.5rem;
      padding-top: 3.5rem;
      padding-right: 3.5rem;
      padding-bottom: 2.3rem;
    }
  }

  .meal {
    padding-bottom: 1rem;

    .number {
      font-size: 1.75rem;

      width: 3.5rem;
      height: 3.5rem;
    }

    .detail {
      margin-left: 1rem;
      border-bottom: 0.032rem solid;
      font-size: 1.75rem;
    }
  }
}

@media (min-width: 1440px) and (orientation: portrait) {
  .menu {
    .top-panel {
      height: 27.93rem;
    }

    .header-text {
      height: 11.305rem;
      font-size: 3.724rem;
      padding-left: 4.655rem;
      margin-bottom: 1.33rem;

      .data-time-wrapper {
        height: 8.645rem;
        padding-right: 2.261rem;

        .date {
          font-size: 2.261rem;
          padding-right: 1.995rem;
        }

        .time {
          font-size: 5.586rem;
        }
      }
    }

    .daily-menu {
      .no-meal {
        font-size: 7rem;
      }
    }

    .meals {
      padding-left: 4.655rem;
      padding-top: 4.655rem;
      padding-right: 4.655rem;
      padding-bottom: 3.059rem;
    }
  }

  .meal {
    padding-bottom: 1.5rem;

    .number {
      font-size: 2.3275rem;

      width: 4.655rem;
      height: 4.655rem;
    }

    .detail {
      margin-left: 1.33rem;
      border-bottom: 0.04256rem solid;
      font-size: 2.3275rem;
    }
  }
}

@media (min-width: 2160px) and (orientation: portrait) {
  .menu {
    .top-panel {
      height: 42rem;
    }

    .header-text {
      height: 17rem;
      font-size: 5.6rem;
      padding-left: 7rem;
      margin-bottom: 2rem;

      .data-time-wrapper {
        height: 13rem;
        padding-right: 3.4rem;

        .date {
          font-size: 3.4rem;
          padding-right: 3rem;
        }

        .time {
          font-size: 8.4rem;
        }
      }
    }

    .daily-menu {
      .no-meal {
        font-size: 9rem;
      }
    }

    .meals {
      padding-left: 7rem;
      padding-top: 7rem;
      padding-right: 7rem;
      padding-bottom: 4.6rem;
    }
  }

  .meal {
    padding-bottom: 2rem;

    .number {
      font-size: 3rem;

      width: 7rem;
      height: 7rem;
    }

    .detail {
      margin-left: 2rem;
      border-bottom: 0.064rem solid;
      font-size: 3.5rem;
    }
  }
}

.flex-165 {
  flex: 165;
}

.flex-27 {
  flex: 27;
}
</style>
