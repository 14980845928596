export const defaultValues = {
  colorTheme: "#569a0d",
  en: {
    currency: "eur",
    noMenuTitle: "No menu",
  },
  cs: {
    currency: "czk",
    noMenuTitle: "Dnes se nevaří",
  },
};
