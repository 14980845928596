import { SelectedMeal } from "./types";

export interface NewDataEventListener {
  (evt: CustomEvent<SelectedMeal[]>): void;
}

export abstract class MenuSource {
  protected ev: EventTarget = new EventTarget();
  protected currency: string;

  constructor(currency: string) {
    this.currency = currency;
  }

  addEventListener(
    type: "new-data" | "error",
    callback: NewDataEventListener,
    options?: boolean | AddEventListenerOptions | undefined
  ): void {
    return this.ev.addEventListener(type, callback as EventListener, options);
  }

  removeEventListener(
    type: "new-data" | "error",
    callback: NewDataEventListener,
    options?: boolean | EventListenerOptions | undefined
  ): void {
    return this.ev.removeEventListener(
      type,
      callback as EventListener,
      options
    );
  }
  abstract start(): void;
  abstract stop(): void;
}
