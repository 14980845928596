import { fetchTimeout } from "@/fetchTimeout";
import { SelectedMeal } from "./types";
import { MenuSource } from "./menuSource";

export class AdapteeMenuSource extends MenuSource {
  private isRunning = false;
  private timeoutInterval = -1;

  constructor(
    private sourceUrl: string,
    private updateInterval: number,
    currency: string
  ) {
    super(currency);
  }

  start(): void {
    this.isRunning = true;
    let lastEtag: string | null = null;
    const loadData = () => {
      const headers = new Headers();
      if (lastEtag !== null) {
        headers.set("etag", lastEtag);
      }
      this.fetchData(this.sourceUrl, headers)
        .then((data) => {
          // Server vrátil 304, proto není potřeba nic dělat.
          if (data === null) {
            return;
          }
          if (this.isRunning) {
            this.ev.dispatchEvent(
              new CustomEvent("new-data", {
                detail: this.parseDataAdaptee(data.json),
              })
            );
            lastEtag = data.etag;
          }
        })
        .catch(() => {
          this.ev.dispatchEvent(new CustomEvent("error"));
        })
        .finally(() => {
          this.timeoutInterval = setTimeout(
            () => loadData(),
            this.updateInterval * 1000
          );
        });
    };
    loadData();
  }

  stop(): void {
    this.isRunning = false;
    clearTimeout(this.timeoutInterval);
  }

  private validateAdapteeResponse(json: any) {
    if (!json.checksum) {
      throw new Error("missing checksum");
    }
    if (!Array.isArray(json.products)) {
      throw new Error("products should be an array");
    }
    // TODO: lepší validace objektu
  }

  private parseDataAdaptee(json: Adaptee): SelectedMeal[] {
    return json.products
      .filter((p) => p.active)
      .map((p, idx) => ({
        available: true,
        name: p.name,
        order: idx + 1,
        price: (p.price.amount / 100).toFixed(2),
        currency: p.price.currency.toLowerCase(),
        soldOut: p.soldOut,
      }));
  }

  private async fetchData(
    url: string,
    headers: Headers
  ): Promise<{
    json: any;
    etag: string | null;
  } | null> {
    const res = await fetchTimeout(url, {
      headers: headers,
    });
    if (res.status === 304) {
      return null;
    }
    if (!res.ok) {
      throw new Error(`response ends with "${res.status}"`);
    }
    const etag = res.headers.get("etag");
    const json = await res.json();
    this.validateAdapteeResponse(json);

    return {
      json,
      etag,
    };
  }
}

interface Adaptee {
  checksum: string;
  products: ProductAdaptee[];
}

interface ProductAdaptee {
  id: string;
  name: string;
  image: string;
  price: ProductPriceAdaptee;
  active: boolean;
  soldOut: boolean;
}

interface ProductPriceAdaptee {
  amount: number;
  currency: "EUR" | "CZK";
}
