<template>
  <div class="full-offer">
    <h1 v-text="$t('screen.fullMenu.title')" />
    <b-modal
      id="modalMeal"
      ref="modalMeal"
      :title="modalMeal.title"
      @ok="modalMeal.onSuccess"
    >
      <template #default="{}">
        <form ref="form">
          <b-form-group :label="$t('screen.fullMenu.modalMeal.mealNameLabel')">
            <b-form-input
              v-model="modalMeal.meal.name"
              :invalidFeedback="
                $t('screen.fullMenu.modalMeal.mealNameInvalidFeedback')
              "
              :state="modalMeal.meal.name.length > 3"
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('screen.fullMenu.modalMeal.mealPriceLabel')">
            <b-form-input
              v-model="modalMeal.meal.price"
              :state="
                modalMeal.meal.price.length > 0 &&
                !isNaN(parseInt(modalMeal.meal.price, 10))
              "
            ></b-form-input>
          </b-form-group>
        </form>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="modal-footer-buttons">
          <div>
            <b-button
              class="mr-2"
              size="sm"
              variant="outline-secondary"
              @click="cancel()"
              v-text="$t('screen.fullMenu.modalMeal.discardChanges')"
            />
            <b-button
              size="sm"
              variant="success"
              @click="ok()"
              v-text="$t('screen.fullMenu.modalMeal.applyChanges')"
            />
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Tool bar -->
    <div class="tool-bar">
      <b-form-group
        :label="$t('screen.fullMenu.toolbar.searchLabel')"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            :placeholder="$t('screen.fullMenu.toolbar.searchPlaceholder')"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              @click="filter = ''"
              v-text="$t('screen.fullMenu.toolbar.clearInputBtn')"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button
        size="sm"
        @click="newMeal()"
        class="mr-1 btn-success"
        v-text="$t('screen.fullMenu.toolbar.newMealBtn')"
      >
      </b-button>
    </div>

    <!-- Table -->
    <b-table
      show-empty
      small
      stacked="md"
      :items="items"
      :fields="header"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
      :empty-text="$t('screen.fullMenu.table.emptyTable')"
    >
      <template #cell(name)="row">
        <div style="text-align: left" v-text="row.value"></div>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="selectMeal(row.item.id)"
          class="mr-1 btn-success"
        >
          <b-icon icon="plus-square"></b-icon>
        </b-button>
        <b-button
          size="sm"
          @click="editMeal(row.item)"
          class="mr-1 btn-warning"
        >
          <b-icon icon="pencil"></b-icon>
        </b-button>
        <b-button size="sm" variant="danger" @click="removeMeal(row.item.id)"
          ><b-icon icon="x-square"></b-icon
        ></b-button>
      </template>
    </b-table>

    <!-- Paging -->
    <div class="paging-wrap">
      <b-pagination
        v-if="totalRows / perPage > 1"
        style="width: 50%"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
    </div>
  </div>
</template>

<script lang="ts">
import { AllMeals, Meal, TableColumn } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

interface TableMeal extends Meal {
  id: string;
}

interface ModalMeal {
  title: string;
  meal: TableMeal;
  onSuccess: () => void;
}

@Component({})
export default class FullMenu extends Vue {
  @Prop() meals!: AllMeals;
  public items: TableMeal[] = [];

  public header: TableColumn[] = [
    {
      key: "name",
      label: this.$t("screen.fullMenu.table.mealNameColumn").toString(),
      sortable: true,
      sortDirection: "desc",
      class: "text-left",
    },
    {
      key: "price",
      label: this.$t("screen.fullMenu.table.mealPriceColumn").toString(),
      sortable: true,
    },
    {
      key: "actions",
      label: this.$t("screen.fullMenu.table.mealActionColumn").toString(),
    },
  ];

  public totalRows = 0;
  public currentPage = 1;
  public perPage = 15;

  public sortBy = "";
  public sortDesc = false;
  public sortDirection = "asc";
  public filter = "";

  public modalMeal: ModalMeal = {
    title: "",
    meal: { id: "", name: "", price: "" },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: () => {},
  };

  @Watch("$i18n.locale", { immediate: true, deep: true })
  private languageChange(): void {
    this.header = [
      {
        key: "name",
        label: this.$t("screen.fullMenu.table.mealNameColumn").toString(),
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "price",
        label: this.$t("screen.fullMenu.table.mealPriceColumn").toString(),
        sortable: true,
      },
      {
        key: "actions",
        label: this.$t("screen.fullMenu.table.mealActionColumn").toString(),
      },
    ];
  }

  @Watch("meals", { immediate: true, deep: true })
  private mealsChanged() {
    this.items = Object.entries(this.meals).map((value: [string, Meal]) => {
      const [id, meal] = value;

      return {
        id,
        name: meal.name,
        price: meal.price,
      };
    });
    this.totalRows = this.items.length;
  }

  public newMeal() {
    this.modalMeal = {
      title: this.$t("screen.fullMenu.modalMeal.newMealTitle").toString(),
      meal: { id: "", name: "", price: "" },
      onSuccess: () => {
        if (this.modalMeal) {
          const meal: Meal = {
            name: this.modalMeal.meal.name,
            price: this.modalMeal.meal.price,
          };
          this.$emit("onAddNewMeal", meal);
        }
      },
    };
    this.$root.$emit("bv::show::modal", "modalMeal");
  }

  public editMeal(meal: TableMeal) {
    this.modalMeal = {
      title: this.$t("screen.fullMenu.modalMeal.editMealTitle").toString(),
      meal: { ...meal },
      onSuccess: () => {
        if (this.modalMeal) {
          const { id, name, price } = this.modalMeal.meal;
          this.$emit("onEditMeal", id, { name, price });
        }
      },
    };
    this.$root.$emit("bv::show::modal", "modalMeal");
  }

  public selectMeal(id: string) {
    this.$emit("onSelectMeal", id);
  }

  public removeMeal(id: string) {
    this.$bvModal
      .msgBoxConfirm(
        this.$t("screen.fullMenu.table.removeMealConfirm.message").toString(),
        {
          title: this.$t(
            "screen.fullMenu.table.removeMealConfirm.title"
          ).toString(),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t(
            "screen.fullMenu.table.removeMealConfirm.yes"
          ).toString(),
          cancelTitle: this.$t(
            "screen.fullMenu.table.removeMealConfirm.no"
          ).toString(),
          footerClass: "p-2",
          hideHeaderClose: false,
        }
      )
      .then((value) => {
        if (value) {
          this.$emit("onRemoveMeal", id);
          this.$root.$emit("bv::hide::modal", "modalMeal");
        }
      });
  }

  onFiltered(filteredItems: any[]) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }
}
</script>

<style scoped lang="less">
@import "../layout.less";

.tool-bar {
  .flex-row();
  .horizontal-space-between();
  margin: 1%;
}

.paging-wrap {
  .flex-row();
  .horizontal-center();
  margin: 1%;
}

.modal-footer-buttons {
  .flex-row();
  .horizontal-end();
  flex: 1;
}
</style>
