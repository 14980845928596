import firebase from "firebase/app";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Screen from "../views/Screen.vue";
import ScreenMenu from "../views/ScreenMenu.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    meta: { title: "Sign up - Ki-Wi Menu" },
  },
  {
    path: "/screen/:id",
    name: "Screen",
    component: Screen,
    meta: { requiresAuth: true },
  },
  {
    path: "/menu/screen/:id",
    name: "ScreenMenu",
    component: ScreenMenu,
    meta: { title: "Menu - Ki-Wi Menu - Ki-Wi Signage" },
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
    meta: { title: "Sign in - Ki-Wi Menu - Ki-Wi Signage" },
  },
  {
    path: "*",
    redirect: "/signin",
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title || "Ki-Wi Menu - Ki-Wi Signage";

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const auth = firebase.auth();
    if (!auth.currentUser) {
      next({
        path: "/signin",
      });
      return;
    }
    next();
    return;
  }

  next();
});

export default router;
