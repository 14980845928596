<template>
  <b-overlay class="overlay" :show="isLoading" rounded="sm">
    <div class="root">
      <img src="../assets/logo.png" alt="" width="150" />
      <div class="kiwi-menu-logo">Ki-Wi Menu</div>
      <Singup
        class="signup-wrapper"
        v-if="!isRegistrationComplete"
        @onRegister="onRegister"
      />
      <div v-else>
        <div class="registration-success">
          {{ $t("signup.thanksForRegistration") }}<br />
          <span
            class="verification-text"
            v-text="$t('signup.verificationEmail')"
          />
          <br />
          <span class="redirect-text"
            >{{ $t("signup.loginText.click") }}
            <router-link to="/#/sigin">
              {{ $t("signup.loginText.here") }}
            </router-link>
            {{ $t("signup.loginText.signin") }}</span
          >
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Singup from "../components/SignUp.vue";
import { NewUser } from "@/types";
import { getSignInUrl, registerUser } from "../firebaseHelpers";
import firebase from "firebase/app";

@Component({
  components: {
    Singup,
  },
})
export default class SignUp extends Vue {
  public isRegistrationComplete = false;
  public isLoading = false;

  private showError(msg: string) {
    this.$bvModal.msgBoxOk(msg, {
      title: "Informace",
      size: "sm",
      buttonSize: "sm",
      okVariant: "danger",
      centered: true,
    });
  }

  public async onRegister(u: NewUser) {
    const auth = firebase.auth();
    this.isLoading = true;

    const parentOrganizationId: string =
      this.$route.params.parentOrganizationId || "";

    try {
      // Vytvoření uživatele s výchozí prodejnou a obrazovku
      const newUser = await registerUser({
        user: {
          email: u.email,
          password: u.password,
        },
        lang: u.lang,
        parentOrganizationId,
        organizationName: u.organizationName,
      });

      if (!newUser || !newUser.token) {
        this.showError(this.$t("signup.otherSignupError").toString());
        this.isLoading = false;
        return;
      }

      const userCredentials = await auth.signInWithCustomToken(newUser.token);
      if (!userCredentials) {
        this.isLoading = false;
        this.showError(this.$t("signup.otherSignupError").toString());
        return;
      }
      auth.languageCode = this.$i18n.locale;
      userCredentials.user?.sendEmailVerification({
        url: getSignInUrl(this.$router),
      });

      this.isLoading = false;
      this.isRegistrationComplete = true;
    } catch (err) {
      if ((err as any).toString().includes("Error: user already exists")) {
        this.showError(this.$t("signup.userAlreadyExists").toString());
      } else {
        console.error(err);
        this.showError(this.$t("signup.otherSignupError").toString());
      }
      this.isLoading = false;
    }
  }
}
</script>

<style scoped lang="less">
@import "../layout.less";
.overlay {
  .full-screen();
}

.root {
  .full-screen();
  background-image: url("../assets/signin-bg.png");
  background-color: rgba(73, 73, 73, 0.5);
  background-size: cover;
  background-position: center center;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 6rem;
}

.signup-wrapper {
  width: 25rem;
}

.kiwi-menu-logo {
  background-color: #39b54a;
  border-radius: 0.5rem;
  font-size: 2.5rem;
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 2.5rem 0 6.3rem 0;
  width: 25rem;
}

.registration-success {
  font-size: 1.8rem;
  background-color: rgba(25, 25, 25, 0.5);
  border-radius: 0.5rem;
  color: #fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 6.3rem;
  margin-top: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  .verification-text {
    font-size: 1.2rem;
  }

  .redirect-text {
    font-size: 1rem;
  }
}
</style>
