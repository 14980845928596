<template>
  <b-modal
    id="modalSettings"
    ref="modalSettings"
    :title="modalTitle"
    @ok="onSubmit"
  >
    <template #default="{}">
      <form ref="form">
        <b-form-group :label="$t('screen.settings.screenName')">
          <b-form-input
            v-model="name"
            :invalidFeedback="$t('screen.settings.screenNameInvalidFeedback')"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('screen.settings.headerText')">
          <b-form-input
            v-model="headerText"
            :invalidFeedback="$t('screen.settings.headerTextInvalidFeedback')"
          ></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('screen.settings.noMenuTitle')">
          <b-form-input
            v-model="noMenuTitle"
            :invalidFeedback="$t('screen.settings.noMenuTitleInvalidFeedback')"
          ></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('screen.settings.currencyLabel')">
          <b-form-select v-model="currency" class="mb-3">
            <b-form-select-option :value="'czk'">CZK</b-form-select-option>
            <b-form-select-option :value="'eur'">EUR</b-form-select-option>
            <b-form-select-option :value="'usd'">USD</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group>
          <div style="display: flex">
            <b-form-checkbox v-model="disableTitle" unchecked-value="false">
              <div v-text="$t('screen.settings.hideTitleText')"></div>
            </b-form-checkbox>
            <b-form-checkbox
              class="ml-4"
              v-model="disableImage"
              unchecked-value="false"
            >
              <div v-text="$t('screen.settings.hideImage')"></div>
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group>
          <label for="screenScale"
            >{{ $t("screen.settings.screenScale")
            }}{{ Math.round(screenScale) }} %</label
          >
          <b-form-input
            id="screenScale"
            v-model="screenScale"
            type="range"
            min="80"
            max="200"
          ></b-form-input>
        </b-form-group>

        <div style="display: flex; flex-flow: row; padding-bottom: 2rem">
          <div
            v-text="$t('screen.settings.colorThemeLabel')"
            style="padding-right: 1rem"
          ></div>
          <verte v-model="color" :picker="'wheel'" :model="'hex'"></verte>
        </div>

        <b-form-group :label="$t('screen.settings.dataSourceType.label')">
          <div style="display: flex">
            <b-form-select v-model="menuSourceType" class="mb-3">
              <b-form-select-option
                :value="menuSourceTypeDefault"
                v-text="$t('screen.settings.dataSourceType.default')"
              ></b-form-select-option>
              <b-form-select-option
                :value="menuSourceTypeAdaptee"
                v-text="$t('screen.settings.dataSourceType.adaptee')"
              ></b-form-select-option>
            </b-form-select>
            <b-form-input
              :disabled="menuSourceType === menuSourceTypeDefault"
              v-model="menuSourceUrl"
            ></b-form-input>
          </div>
        </b-form-group>

        <b-form-group :label="$t('screen.settings.topBoldCountLabel')">
          <b-form-spinbutton
            v-model="topBoldCount"
            wrap
            min="0"
            max="12"
            placeholder=""
          ></b-form-spinbutton>
        </b-form-group>

        <b-form-group :label="$t('screen.settings.landscapeBackground')">
          <b-form-file
            v-model="backgroundImageLandscape"
            :state="Boolean(backgroundImageLandscape)"
            :placeholder="$t('screen.settings.filePlaceholder')"
            :drop-placeholder="$t('screen.settings.dropPlaceholder')"
          ></b-form-file>
        </b-form-group>

        <b-form-group :label="$t('screen.settings.portraitBackground')">
          <b-form-file
            v-model="backgroundImagePortrait"
            :state="Boolean(backgroundImagePortrait)"
            :placeholder="$t('screen.settings.filePlaceholder')"
            :drop-placeholder="$t('screen.settings.dropPlaceholder')"
          ></b-form-file>
        </b-form-group>
      </form>
    </template>

    <template #modal-footer="{ ok, cancel }">
      <div class="modal-footer-buttons">
        <div>
          <b-button
            size="sm"
            class="mr-2"
            variant="outline-secondary"
            @click="cancel()"
            v-text="$t('screen.settings.discardChanges')"
          />
          <b-button
            size="sm"
            variant="success"
            @click="ok()"
            v-text="$t('screen.settings.applyChanges')"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ScreenSettings, MenuSourceType } from "../types";
import { defaultValues } from "@/defaultValues";

@Component({})
export default class FullMenu extends Vue {
  @Prop() settings!: ScreenSettings;

  public name = "";
  public color: string;
  public headerText = "";
  public noMenuTitle = "";
  public currency: string;
  public disableTitle = false;
  public disableImage = false;
  public backgroundImagePortrait: File = new File([], "");
  public backgroundImageLandscape: File = new File([], "");
  public screenScale = 100;
  public menuSourceType = MenuSourceType.Default;
  public menuSourceUrl = "";
  public topBoldCount = 1;
  public menuSourceTypeDefault = MenuSourceType.Default;
  public menuSourceTypeAdaptee = MenuSourceType.Adaptee;

  public modalTitle = this.$t("screen.settings.title").toString();

  constructor() {
    super();
    const lang = this.$i18n.locale;
    this.color = defaultValues.colorTheme;
    this.currency = defaultValues[lang].currency;
  }

  @Watch("$i18n.locale", { immediate: true, deep: true })
  private languageChange(): void {
    this.modalTitle = this.$t("screen.settings.title").toString();
  }

  public onSubmit() {
    const settings: ScreenSettings = {
      name: this.name,
      color: this.color,
      noMenuTitle: this.noMenuTitle,
      currency: this.currency,
      headerText: this.headerText,
      disableTitle: this.disableTitle,
      disableImage: this.disableImage,
      screenScale: this.screenScale / 100,
      backgroundImagePortrait:
        this.backgroundImagePortrait.size === 0
          ? undefined
          : this.backgroundImagePortrait,
      backgroundImageLandscape:
        this.backgroundImageLandscape.size === 0
          ? undefined
          : this.backgroundImageLandscape,
      topBoldCount: this.topBoldCount,
      menuSourceUrl: this.menuSourceUrl,
      menuSourceType: this.menuSourceType,
    };
    this.$emit("onScreenSettingsChanged", settings);
  }

  @Watch("settings", { immediate: true, deep: true })
  private settingsChanged() {
    if (!this.settings) {
      return;
    }

    this.name = this.settings.name;
    this.color = this.settings.color;
    this.headerText = this.settings.headerText;
    this.noMenuTitle = this.settings.noMenuTitle;
    this.currency = this.settings.currency;
    this.disableTitle = this.settings.disableTitle;
    this.disableImage = this.settings.disableImage;
    this.screenScale = this.settings.screenScale * 100;
    this.menuSourceType = this.settings.menuSourceType;
    this.menuSourceUrl = this.settings.menuSourceUrl;
    this.topBoldCount = this.settings.topBoldCount;
  }
}
</script>

<style scoped lang="less">
@import "../layout.less";

.tool-bar {
  .flex-row();
  .horizontal-space-between();
  margin: 1%;
}

.paging-wrap {
  .flex-row();
  .horizontal-center();
  margin: 1%;
}

.modal-footer-buttons {
  .flex-row();
  .horizontal-end();
  flex: 1;
}
</style>
