import { register } from "register-service-worker";

export class ServiceWorker {
  private fragment: DocumentFragment = document.createDocumentFragment();

  constructor(public swUrl: string) {
    navigator.serviceWorker.onmessage = this.onmessage.bind(this);
  }

  private onmessage(event: any) {
    if (event.data && event.data.type === "FORCE_REFRESH") {
      this.fragment.dispatchEvent(new CustomEvent("new-version"));
    }
  }

  public register() {
    register(this.swUrl, {
      updated: (registration: ServiceWorkerRegistration) => {
        console.info("New content is available.");
        this.updateContent(registration);
      },
      offline() {
        console.info(
          "No internet connection found. App is running in offline mode."
        );
      },
      error(error) {
        console.error("Error during service worker registration:", error);
      },
    });
  }

  private updateContent(registration: ServiceWorkerRegistration): void {
    if (!registration.waiting) {
      // Just to ensure registration.waiting is available before
      // calling postMessage()
      return;
    }
    registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }

  public addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void {
    this.fragment.addEventListener(type, listener, options);
  }

  public removeEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
  ): void {
    this.fragment.removeEventListener(type, callback, options);
  }
}

export const serviceWorker = new ServiceWorker(`service-worker.js`);
serviceWorker.register();
