<template>
  <div>
    <h1 v-text="$t('screen.dailyMenu.title')" />
    <b-modal
      id="modalMealDaily"
      ref="modalMealDaily"
      :title="modalMealDaily.title"
      @ok="modalMealDaily.onSuccess"
    >
      <template #default="{}">
        <form ref="form">
          <b-form-group
            :label="$t('screen.dailyMenu.modalMealDaily.mealNameLabel')"
          >
            <b-form-input
              v-model="modalMealDaily.meal.name"
              :invalidFeedback="
                $t('screen.dailyMenu.modalMealDaily.mealNameInvalidFeedback')
              "
              :state="modalMealDaily.meal.name.length > 3"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="$t('screen.dailyMenu.modalMealDaily.mealPriceLabel')"
          >
            <b-form-input
              v-model="modalMealDaily.meal.price"
              :state="
                modalMealDaily.meal.price.length > 0 &&
                !isNaN(parseInt(modalMealDaily.meal.price, 10))
              "
            ></b-form-input>
          </b-form-group>
        </form>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="modal-footer-buttons">
          <div>
            <b-button
              class="mr-2"
              size="sm"
              variant="success"
              @click="ok()"
              v-text="$t('screen.dailyMenu.modalMealDaily.applyChanges')"
            />
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="cancel()"
              v-text="$t('screen.dailyMenu.modalMealDaily.discardChanges')"
            />
          </div>
        </div>
      </template>
    </b-modal>
    <div class="tool-bar">
      <b-button
        size="sm"
        @click="removeAll()"
        :disabled="isDisabledRemoveAll"
        class="mr-1 btn-danger"
        v-text="$t('screen.dailyMenu.toolbar.removeAllBtn')"
      />
    </div>

    <b-table
      show-empty
      small
      stacked="md"
      :items="items"
      :fields="header"
      :empty-text="$t('screen.dailyMenu.table.emptyTable')"
    >
      <template #cell(availability)="row">
        <b-form-checkbox v-model="row.item.available" @input="edit(row.item)">
        </b-form-checkbox>
      </template>

      <template #cell(screennumber)="row">
        <div v-text="row.item.order"></div>
      </template>

      <template #cell(name)="row">
        <div class="text-left">{{ row.value }}</div>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="editMeal(row.item)"
          class="mr-1 btn-warning"
        >
          <b-icon icon="pencil"></b-icon>
        </b-button>
        <b-button
          size="sm"
          @click="moveUp(row.item)"
          variant="success"
          class="mr-1"
        >
          <b-icon icon="arrow-up-square" aria-label="Move up"></b-icon>
        </b-button>
        <b-button
          size="sm"
          @click="moveDown(row.item)"
          variant="info"
          class="mr-1"
        >
          <b-icon icon="arrow-down-square" aria-label="Move donw"></b-icon>
        </b-button>
        <b-button
          size="sm"
          @click="remove(row.item.id)"
          class="mr-1"
          variant="danger"
        >
          <b-icon icon="x-square" aria-label="Remove"></b-icon>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { SelectedMeal, SelectedMeals, TableColumn } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

interface TableMeal extends SelectedMeal {
  id: string;
}

interface ModalMeal {
  title: string;
  meal: TableMeal;
  onSuccess: () => void;
}

@Component({
  components: {},
})
export default class DailyMenu extends Vue {
  @Prop() meals!: SelectedMeals;

  public items: TableMeal[] = [];

  public header: TableColumn[] = [
    { key: "availability", label: "" },
    {
      key: "screennumber",
      label: this.$t("screen.dailyMenu.table.numberColumn").toString(),
    },
    {
      key: "name",
      label: this.$t("screen.dailyMenu.table.mealNameColumn").toString(),
      class: "text-left",
    },
    {
      key: "price",
      label: this.$t("screen.dailyMenu.table.mealPriceColumn").toString(),
    },
    {
      key: "actions",
      label: this.$t("screen.dailyMenu.table.mealActionColumn").toString(),
    },
  ];

  public modalMealDaily: ModalMeal = {
    title: "",
    meal: { id: "", name: "", price: "", available: false, order: -1 },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: () => {},
  };

  @Watch("$i18n.locale", { immediate: true, deep: true })
  private languageChange(): void {
    this.header = [
      { key: "availability", label: "" },
      {
        key: "screennumber",
        label: this.$t("screen.dailyMenu.table.numberColumn").toString(),
      },
      {
        key: "name",
        label: this.$t("screen.dailyMenu.table.mealNameColumn").toString(),
        class: "text-left",
      },
      {
        key: "price",
        label: this.$t("screen.dailyMenu.table.mealPriceColumn").toString(),
      },
      {
        key: "actions",
        label: this.$t("screen.dailyMenu.table.mealActionColumn").toString(),
      },
    ];
  }

  @Watch("meals", { immediate: true, deep: true })
  private mealsChanged() {
    this.items = Object.entries(this.meals)
      .map((value: [string, SelectedMeal]) => {
        const [id, meal] = value;

        return {
          id,
          name: meal.name,
          price: meal.price,
          available: meal.available,
          order: meal.order,
        };
      })
      .sort((a: TableMeal, b: TableMeal) => {
        return a.order - b.order;
      });
  }

  get isDisabledRemoveAll(): boolean {
    return this.items.length === 0;
  }

  public editMeal(meal: TableMeal) {
    this.modalMealDaily = {
      title: this.$t(
        "screen.dailyMenu.modalMealDaily.editMealTitle"
      ).toString(),
      meal: { ...meal },
      onSuccess: () => {
        if (this.modalMealDaily) {
          this.edit(this.modalMealDaily.meal);
        }
      },
    };
    this.$root.$emit("bv::show::modal", "modalMealDaily");
  }

  public edit(meal: TableMeal) {
    this.$emit("onEdit", meal.id, {
      name: meal.name,
      price: meal.price,
      available: meal.available,
      order: meal.order,
    });
  }

  public remove(id: string) {
    this.$emit("onRemove", [id]);
  }

  public removeAll() {
    this.$bvModal
      .msgBoxConfirm(
        this.$t(
          "screen.dailyMenu.toolbar.removeAllBtnConfirmMsgBox.message"
        ).toString(),
        {
          title: this.$t(
            "screen.dailyMenu.toolbar.removeAllBtnConfirmMsgBox.title"
          ).toString(),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t(
            "screen.dailyMenu.toolbar.removeAllBtnConfirmMsgBox.yes"
          ).toString(),
          cancelTitle: this.$t(
            "screen.dailyMenu.toolbar.removeAllBtnConfirmMsgBox.no"
          ).toString(),
          footerClass: "p-2",
          hideHeaderClose: false,
        }
      )
      .then((value) => {
        if (value) {
          this.$emit("onRemove", Object.keys(this.meals));
          this.$root.$emit("bv::hide::modal", "modalMeal");
        }
      });
  }

  public moveUp(meal: TableMeal) {
    let nextIndex = meal.order - 1;
    if (nextIndex === 0) {
      nextIndex = Object.keys(this.meals).length;
    }
    const f = this.items.find((f: TableMeal) => {
      return f.order === nextIndex;
    });
    if (!f) {
      return;
    }

    const tmpOrder = meal.order;
    meal.order = f.order;
    f.order = tmpOrder;

    this.edit(f);
    this.edit(meal);
  }

  public moveDown(meal: TableMeal) {
    let nextIndex = meal.order + 1;
    if (nextIndex > Object.keys(this.meals).length) {
      nextIndex = 1;
    }
    const f = this.items.find((f: TableMeal) => {
      return f.order === nextIndex;
    });
    if (!f) {
      return;
    }

    const tmpOrder = meal.order;
    meal.order = f.order;
    f.order = tmpOrder;

    this.edit(f);
    this.edit(meal);
  }
}
</script>

<style scoped lang="less">
@import "../layout.less";

.tool-bar {
  .flex-row();
  .horizontal-center();
  margin: 1%;
}

.text-left {
  text-align: left;
}
</style>
